import { ReactComponent as AddSymbol } from '@material-symbols/svg-400/outlined/add.svg';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { SvgIcon, LottieLogoPageLoader, XCloseIcon24, theme } from '@stationwise/component-module';
import { InstantHireView } from '@stationwise/share-types';
import { InstantHireHistoryCard } from './InstantHireHistoryCard';
import { InstantHireHistoryDetails } from './InstantHireHistoryDetails';

interface InstantHireHistoryProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreateInstantHireModalOpen: (open: boolean) => void;
  instantHires: InstantHireView[];
  hasVacancies: boolean;
  hasRosterChanges: boolean;
  isRefetching: boolean;
}

export const InstantHireHistory = ({
  instantHires,
  open,
  setOpen,
  setCreateInstantHireModalOpen,
  hasVacancies,
  hasRosterChanges,
  isRefetching,
}: InstantHireHistoryProps) => {
  const [selectedInstantHire, setSelectedInstantHire] = useState<InstantHireView>(instantHires[0]);
  const [openInstantHireHistoryDetails, setOpenInstantHireHistoryDetails] = useState(false);

  useEffect(() => {
    const updatedInstantHire = instantHires.find((hire) => hire.id === selectedInstantHire?.id);
    setSelectedInstantHire(updatedInstantHire || instantHires[0]);
  }, [instantHires, selectedInstantHire?.id]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiDialog-paper': {
          width: '450px',
          minHeight: '400px',
        },
      }}
    >
      {isRefetching && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: theme.zIndex.modal + 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LottieLogoPageLoader />
        </Box>
      )}
      <DialogTitle>
        {!isRefetching && (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
              <Box sx={{ typography: 'bodyXLSemibold' }}>Instant Hire History</Box>
              <Typography variant="bodySRegular">{format(selectedInstantHire.shiftDate, 'MMM d, yyyy')}</Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <XCloseIcon24 />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {!isRefetching && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="buttonL">Instant Hire History</Typography>
                {hasVacancies && (
                  <Tooltip title={hasRosterChanges ? 'You should save your roster changes before starting an instant hire' : ''}>
                    <span>
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<SvgIcon component={AddSymbol} />}
                        onClick={() => setCreateInstantHireModalOpen(true)}
                        disabled={hasRosterChanges}
                      >
                        Start Instant Hire
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {instantHires.map((instantHire, index) => (
                  <InstantHireHistoryCard
                    key={instantHire.id}
                    index={index + 1}
                    instantHire={instantHire}
                    setSelectedInstantHire={setSelectedInstantHire}
                    setOpenInstantHireHistoryDetails={setOpenInstantHireHistoryDetails}
                  />
                ))}
              </Box>

              <InstantHireHistoryDetails
                instantHire={selectedInstantHire}
                open={openInstantHireHistoryDetails}
                setOpen={setOpenInstantHireHistoryDetails}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
