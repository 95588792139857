import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { ReactComponent as EditSymbol } from '@material-symbols/svg-400/outlined/edit-fill.svg';
import { Box, Button, Menu, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { RankBadge, formatDate, SnackbarService, SvgIcon } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Responsibility } from '@stationwise/share-types';
import { AddResponsibilityModal } from './AddResponsibilityModal';
import { EditResponsibilityModal } from './EditResponsibilityModal';
interface ResponsibilityContentProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  currentDate: Date;
}

export const ResponsibilityContent = ({ anchorEl, handleClose, currentDate }: ResponsibilityContentProps) => {
  const [responsibilities, setResponsibilities] = useState<Responsibility[]>([]);
  const [showAddNewResponsibilityModel, setShowAddNewResponsibilityModel] = useState(false);
  const [showEditResponsibilityModel, setShowEditResponsibilityModel] = useState(false);

  const [fetchFlag, setFetchFlag] = useState(false);
  const [selectedResponsibility, setSelectedResponsibility] = useState<Responsibility | null>(null);
  const open = Boolean(anchorEl);

  const forceFetch = () => {
    setFetchFlag((prevFlag) => !prevFlag);
  };

  useEffect(() => {
    const fetchResponsibility = async (shiftDate: string) => {
      try {
        const response = await client.get('/shift/responsibility/', {
          params: {
            date: shiftDate,
          },
        });

        setResponsibilities(response.data);
      } catch (err) {
        captureException(err);
        SnackbarService.notify({
          content: 'Unexpected error. Try again later.',
          severity: 'error',
          showCloseButton: true,
          duration: 5000,
        });
      }
    };

    fetchResponsibility(formatDate(currentDate));
  }, [fetchFlag, setResponsibilities, currentDate]);

  const handleAddNewResponsibilityClick = () => {
    setShowAddNewResponsibilityModel(true);
  };

  return (
    <Box>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -8, horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        sx={(theme) => ({
          top: theme.spacing(0),
          '& .MuiMenu-paper': {
            border: `1px solid ${theme.palette.stationGray[100]}`,
            borderRadius: 4,
            boxShadow: '0px 10px 10px -5px #0A0E160A, 0px 20px 25px -5px #0A0E161A',
            color: theme.palette.stationGray[900],
            p: 2.5,
          },
          '& .MuiMenu-list': { p: 0 },
          '& .MuiListItem-root': { backgroundColor: 'transparent', display: 'block', p: 0 },
        })}
      >
        <Box sx={{ minWidth: '500px' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              typography: theme.typography.h6,
            })}
          >
            Responsibilities
            <Box sx={(theme) => ({ color: theme.palette.stationGray[500], cursor: 'pointer' })} onClick={handleClose}>
              <SvgIcon component={CloseSymbol} />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: 2,
              overflow: 'auto',
              flexDirection: 'column',
              mb: theme.spacing(0),
            })}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Person</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responsibilities.map((responsibility) => (
                  <TableRow key={responsibility.id}>
                    <TableCell>{responsibility.name}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-between">
                        <Box
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.5,
                            textAlign: 'left',
                            width: '100%',
                          }}
                        >
                          {responsibility.employee && <RankBadge rank={responsibility.employee.rank} />}
                          <Box sx={{ flex: 1, typography: 'body2' }}>{responsibility.employee?.name}</Box>
                        </Box>

                        <Button
                          sx={(theme) => ({
                            padding: theme.spacing(0),
                            color: theme.palette.stationGray[500],
                          })}
                          onClick={() => {
                            setSelectedResponsibility(responsibility);
                            setShowEditResponsibilityModel(true);
                          }}
                        >
                          <SvgIcon component={EditSymbol} fill={''} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box
            onClick={handleAddNewResponsibilityClick}
            sx={(theme) => ({
              color: theme.palette.stationBlue[500],
              typography: theme.typography.buttonM,
              px: theme.spacing(1),
              pt: theme.spacing(3),
              pb: theme.spacing(1),
              width: 'max-content',
              cursor: 'pointer',
            })}
          >
            Add New
          </Box>
        </Box>
      </Menu>
      <AddResponsibilityModal
        open={showAddNewResponsibilityModel}
        setOpen={setShowAddNewResponsibilityModel}
        currentDate={currentDate}
        forceFetch={forceFetch}
      />
      <EditResponsibilityModal
        open={showEditResponsibilityModel}
        setOpen={setShowEditResponsibilityModel}
        currentDate={currentDate}
        forceFetch={forceFetch}
        responsibility={{
          name: selectedResponsibility?.name,
          id: selectedResponsibility?.id,
          employee: selectedResponsibility?.employee,
        }}
      />
    </Box>
  );
};
