import { Box, FormControl, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import {
  Button,
  DatePickerComp,
  exportData,
  theme,
  useDepartmentInfoContext,
  MultipleSelect,
} from '@stationwise/component-module';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';
import { formatTimePeriod } from '@stationwise/share-utils';
import { SelectionBox } from './SelectionBox';

interface PayrollExportModalProps {
  exportModelOpen: boolean;
  setExportModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployeePayrolls: EmployeePayroll[];
  employeePayrolls: EmployeePayroll[];
  selectedFilters: Record<string, string>;
  currentPayPeriods: PayPeriod[];
}

export const PayrollExportModal = ({
  exportModelOpen,
  setExportModelOpen,
  selectedEmployeePayrolls,
  employeePayrolls,
  selectedFilters,
  currentPayPeriods,
}: PayrollExportModalProps) => {
  const [selectedCurrentPayPeriod, setSelectedCurrentPayPeriod] = useState<PayPeriod | null>(currentPayPeriods[0]);
  const [customPeriodStartDate, setCustomPeriodStartDate] = useState<Date | null>(parseISO(currentPayPeriods[0].startDate));
  const [customPeriodEndDate, setCustomPeriodEndDate] = useState<Date | null>(parseISO(currentPayPeriods[0].endDate));
  const [selectedExportType, setSelectedExportType] = useState('csv');
  const [selectedPeriodType, setSelectedPeriodType] = useState<string>('current');
  const [isSumByExportPeriod, setIsSumByExportPeriod] = useState<boolean>(false);
  const { state: loadedDepartmentInfo } = useDepartmentInfoContext();
  const [selectedPayCodes, setSelectedPayCodes] = useState<string[]>([]);
  const [selectedDetailCodes, setSelectedDetailCodes] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);

  const paycodeOptions = loadedDepartmentInfo?.departmentInfo?.payCodes.map((payCode) => ({
    label: payCode.name,
    value: payCode.id.toString(),
  }));

  const detailCodeOptions = loadedDepartmentInfo?.departmentInfo?.detailCodes.map((detailCode) => ({
    label: detailCode.name,
    value: detailCode.id.toString(),
  }));

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedPeriod = currentPayPeriods.find((period) => `${period.startDate} - ${period.endDate}` === event.target.value);
    if (selectedPeriod) {
      setSelectedCurrentPayPeriod(selectedPeriod);
    }
  };
  const isFilterEmpty =
    Object.values(selectedFilters).every((value) => value === '') || Object.keys(selectedFilters).length === 0;

  const handleExport = async () => {
    const params = {
      selected_employee_ids:
        selectedEmployeePayrolls.length === employeePayrolls.length
          ? null
          : selectedEmployeePayrolls.map((employeePayroll) => employeePayroll.employee.id).join(','),
      start_date:
        selectedPeriodType === 'custom'
          ? customPeriodStartDate
            ? format(customPeriodStartDate, 'yyyy-MM-dd')
            : null
          : selectedCurrentPayPeriod?.startDate,
      end_date:
        selectedPeriodType === 'custom'
          ? customPeriodEndDate
            ? format(customPeriodEndDate, 'yyyy-MM-dd')
            : null
          : selectedCurrentPayPeriod?.endDate,
      is_sum_by_export_period: isSumByExportPeriod,
      export_type: selectedExportType,
      pay_code_ids: selectedPayCodes.length > 0 ? selectedPayCodes.join(',') : null,
      detail_code_ids: selectedDetailCodes.length > 0 ? selectedDetailCodes.join(',') : null,
      ...selectedFilters,
    };

    await exportData('payroll/bulk-export/', params, 'payroll');
    setExportModelOpen(false);
  };

  const clearAllFilters = () => {
    setSelectedPayCodes([]);
    setSelectedDetailCodes([]);
    setShowFilters(false);
  };

  const hasFilters = selectedPayCodes.length > 0 || selectedDetailCodes.length > 0;

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={exportModelOpen}
      onClose={() => setExportModelOpen(false)}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          width: theme.spacing(60),
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: '800px',
            gap: theme.spacing(1),
            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Export time cards</Typography>

          <Typography sx={{ typography: 'bodyMRegular' }}>
            {selectedEmployeePayrolls.length === employeePayrolls.length ? (
              isFilterEmpty ? (
                'For entire department'
              ) : (
                'For entire filtered list'
              )
            ) : selectedEmployeePayrolls.length === 1 ? (
              <>
                For <strong>{selectedEmployeePayrolls[0].employee.name}</strong>{' '}
              </>
            ) : (
              <>
                For <strong>{selectedEmployeePayrolls.length}</strong> employees
              </>
            )}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(1),
            marginX: theme.spacing(3),
            gap: theme.spacing(1),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Choose export type</Typography>
          <Box
            sx={(theme) => ({
              display: 'fixed',
              flexDirection: 'column',
              marginTop: theme.spacing(0.5),
              marginBottom: theme.spacing(2),
              gap: theme.spacing(1.5),
              width: theme.spacing(21),
              height: theme.spacing(5.5),
            })}
          >
            <SelectionBox isSelected={selectedExportType === 'csv'} onClick={() => setSelectedExportType('csv')} label="CSV" />
            <SelectionBox isSelected={selectedExportType === 'iif'} onClick={() => setSelectedExportType('iif')} label="IIF" />
          </Box>
          <Typography sx={{ typography: 'bodySMedium' }}>Choose time period</Typography>
          <Box
            sx={(theme) => ({
              display: 'fixed',
              flexDirection: 'column',
              marginTop: theme.spacing(0.5),
              gap: theme.spacing(1.5),
              width: theme.spacing(21),
              height: theme.spacing(5.5),
            })}
          >
            <SelectionBox
              isSelected={selectedPeriodType === 'current'}
              onClick={() => setSelectedPeriodType('current')}
              label="Current Pay Period"
            />
            <SelectionBox
              isSelected={selectedPeriodType === 'custom'}
              onClick={() => setSelectedPeriodType('custom')}
              label="Custom"
            />
          </Box>

          {selectedPeriodType === 'current' &&
            (currentPayPeriods.length === 1 ? (
              <Typography
                sx={{
                  marginTop: theme.spacing(1),
                  color: theme.palette.stationGray[500],
                  typography: 'bodyMRegular',
                }}
              >
                Current Pay Period:
                {formatTimePeriod(currentPayPeriods[0].startDate, currentPayPeriods[0].endDate)}
              </Typography>
            ) : (
              <Box
                sx={{
                  color: theme.palette.stationGray[500],
                  typography: 'bodyMRegular',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(1),
                  marginTop: theme.spacing(2),
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                Current Pay Period
                <FormControl>
                  <Select
                    value={`${selectedCurrentPayPeriod?.startDate || ''} - ${selectedCurrentPayPeriod?.endDate || ''}`}
                    onChange={handleChange}
                  >
                    {currentPayPeriods.map((period, index) => (
                      <MenuItem key={index} value={`${period.startDate} - ${period.endDate}`}>
                        {formatTimePeriod(currentPayPeriods[index].startDate, currentPayPeriods[index].endDate)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ))}
          {selectedPeriodType === 'custom' && (
            <Box>
              <Box sx={(theme) => ({ mb: theme.spacing(2.5), mt: theme.spacing(2.5) })}>
                <DatePickerComp
                  disabled={false}
                  setValue={setCustomPeriodStartDate}
                  value={customPeriodStartDate}
                  openIconPosition="start"
                  sxProps={{ '& .MuiInputBase-input': { pl: '0px' } }}
                />
              </Box>
              <Box sx={(theme) => ({ typography: 'bodySMedium', mb: theme.spacing(0.5) })}>End date</Box>
              <Box>
                <DatePickerComp
                  setValue={setCustomPeriodEndDate}
                  value={customPeriodEndDate}
                  openIconPosition="start"
                  sxProps={{
                    '& .MuiInputBase-input': { pl: '0px' },
                    '& .MuiPickersDay-root': {
                      '& .Mui-selected': {
                        backgroundColor: 'black',
                      },
                      '&:hover, &:focus': {
                        backgroundColor: 'darkred',
                      },
                    },
                  }}
                  minDate={customPeriodStartDate}
                />
              </Box>
            </Box>
          )}

          <Typography sx={{ typography: 'bodySMedium', mt: theme.spacing(1.5) }}>Sum By</Typography>

          <Box
            sx={(theme) => ({
              display: 'fixed',
              flexDirection: 'column',
              marginTop: theme.spacing(0.5),
              gap: theme.spacing(1.5),
              width: theme.spacing(21),
              height: theme.spacing(5.5),
            })}
          >
            <SelectionBox isSelected={!isSumByExportPeriod} onClick={() => setIsSumByExportPeriod(false)} label="Day" />
            <SelectionBox isSelected={isSumByExportPeriod} onClick={() => setIsSumByExportPeriod(true)} label="Export Period" />
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.stationGray[200]}`,
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1),
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Typography sx={{ typography: 'bodyLSemibold' }}>Filters</Typography>
            {!showFilters && !hasFilters && (
              <Button
                buttonType="tertiary"
                onClick={() => setShowFilters(true)}
                sx={(theme) => ({
                  border: 'none',
                  color: theme.palette.primary.main,
                })}
              >
                Add Filter
              </Button>
            )}
            {hasFilters && (
              <Button
                buttonType="tertiary"
                onClick={clearAllFilters}
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                  '&:hover': { backgroundColor: 'transparent' },
                })}
              >
                Remove All
              </Button>
            )}
          </Box>

          {!showFilters && !hasFilters && (
            <Typography
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyMSemiBold',
              })}
            >
              No filters applied
            </Typography>
          )}

          {(showFilters || hasFilters) && (
            <Box sx={{ mt: hasFilters ? 0 : 'auto' }}>
              <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
                <Typography sx={{ mb: 0.5, color: theme.palette.stationGray[500] }}>Pay code</Typography>
                <Box
                  sx={(theme) => ({
                    py: theme.spacing(1),
                    display: 'block',
                  })}
                >
                  <MultipleSelect
                    items={paycodeOptions || []}
                    selectedItems={selectedPayCodes}
                    setSelectedItems={setSelectedPayCodes}
                    placeholder="Select pay code/s"
                    hideValue={true}
                    showLabel={true}
                    chipStyles={{ color: theme.palette.stationGray[700], boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.12)' }}
                  />
                </Box>
              </FormControl>

              <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
                <Typography sx={{ mb: 0.5, color: theme.palette.stationGray[500] }}>Detail code</Typography>
                <Box
                  sx={(theme) => ({
                    py: theme.spacing(1),
                    display: 'block',
                  })}
                >
                  <MultipleSelect
                    items={detailCodeOptions || []}
                    selectedItems={selectedDetailCodes}
                    setSelectedItems={setSelectedDetailCodes}
                    placeholder="Select detail code/s"
                    hideValue={true}
                    showLabel={true}
                    chipStyles={{ color: theme.palette.stationGray[700], boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.12)' }}
                  />
                </Box>
              </FormControl>
            </Box>
          )}
        </Box>

        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            mt: theme.spacing(3),
            gap: theme.spacing(1.5),
            width: '100%',
            padding: theme.spacing(2),
          })}
        >
          <Button onClick={() => setExportModelOpen(false)} buttonType="tertiary" sx={() => ({ width: '100%' })}>
            {'Cancel'}
          </Button>
          <Button
            buttonType="primary"
            onClick={handleExport}
            sx={() => ({ width: '100%', backgroundColor: theme.palette.stationGray[900] })}
            disabled={
              selectedPeriodType === 'custom' &&
              !!customPeriodStartDate &&
              !!customPeriodEndDate &&
              customPeriodStartDate > customPeriodEndDate
            }
          >
            {'Export'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
