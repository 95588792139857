import { Box, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import { Button, Input } from '@stationwise/component-module';
import { usePatchRequest } from './PatchRequestProvider';

interface EditBasicInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  employeeFirstName: string;
  employeeLastName: string;
}

export const EditBasicInfo = ({ showModal, setShowModal, employeeFirstName, employeeLastName }: EditBasicInfoProps) => {
  const [firstName, setFirstName] = useState(employeeFirstName);
  const [lastName, setLastName] = useState(employeeLastName);
  const { handlePatchRequest } = usePatchRequest();
  const handleSave = () => {
    const data = { firstName: firstName, lastName: lastName };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setFirstName(employeeFirstName);
    setLastName(employeeLastName);
  };

  const isDisabled = useMemo(() => {
    const noChanges = firstName === employeeFirstName && lastName === employeeLastName;
    const emptyField = firstName === '' || lastName === '';
    return noChanges || emptyField;
  }, [firstName, lastName, employeeFirstName, employeeLastName]);

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Basic info
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(1),
              width: '100%',
            })}
          >
            <Box
              sx={(theme) => ({
                marginRight: theme.spacing(1),
                width: '100%',
              })}
            >
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                First name
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <Input
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.currentTarget.value);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                marginLeft: theme.spacing(1),
                width: '100%',
              })}
            >
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Last name
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <Input
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.currentTarget.value);
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Cancel
              </Box>
            </Button>
            <Button
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => handleSave()}
              disabled={isDisabled}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Save
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
