import { Box, Typography } from '@mui/material';
import { AutoHireErrorIcon, Button, theme } from '@stationwise/component-module';

export const AutoHireErrorPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <AutoHireErrorIcon />
      <Box
        sx={{
          marginTop: '45px',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          No positions available
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '300px',
          textAlign: 'center',
          marginTop: theme.spacing(2),
        }}
      >
        <Typography variant="body2" gutterBottom>
          All positions have been filled, or auto hire was stopped
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: '35px',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Return To Dashboard
        </Button>
      </Box>
    </Box>
  );
};
