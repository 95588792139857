import { Alert, Autocomplete, Box, TextField, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInHours, differenceInMinutes, isSameDay, isAfter, areIntervalsOverlapping } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePickerComp,
  GenericDrawerOrModal,
  SimpleTimePicker,
  Trash02Icon16 as TrashIcon,
  AddLater,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Apparatus, DynamicFieldsData, Employee, IncidentApparatus } from '@stationwise/share-types';

interface StaffingDetailsDrawerProps {
  onClose: () => void;
  open: boolean;
  incidentStartDatetime: Date;
  handleSave: () => void;
  incidentApparatuses: IncidentApparatus[];
  setIncidentApparatuses: (incidentApparatuses: IncidentApparatus[]) => void;
  isActive: boolean;
  customData: DynamicFieldsData;
  isCloseIncident: boolean;
}

export const StaffingDetailsDrawer = ({
  open,
  onClose,
  incidentStartDatetime,
  handleSave,
  incidentApparatuses,
  setIncidentApparatuses,
  isActive,
  customData,
  isCloseIncident,
}: StaffingDetailsDrawerProps) => {
  const [personnelOptions, setPersonnelOptions] = useState<IncidentApparatus['personnel']>([]);
  const [apparatusOptions, setApparatusOptions] = useState<Apparatus[]>([]);
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isNapaCounty =
    departmentInfoState.departmentInfo.domain.toLowerCase() === 'napacounty' ||
    departmentInfoState.departmentInfo.domain.toLowerCase() === 'demo';

  const checkNapaWildfireCondition = (apparatus: IncidentApparatus) => {
    if (!apparatus.endDatetime || !apparatus.startDatetime || !apparatus.apparatus || !isNapaCounty) {
      return false;
    }
    const duration = differenceInHours(apparatus.endDatetime, apparatus.startDatetime);

    return (
      typeof customData['incidentType'] === 'string' &&
      customData['incidentType'].toLowerCase().includes('fire: vegetation') &&
      ((apparatus?.apparatus?.model.toLowerCase().includes('water tender') && duration >= 5) ||
        (apparatus?.apparatus?.model.toLowerCase().includes('engine') && duration >= 12))
    );
  };
  const checkApparatusOverlap = useMemo(() => {
    return (index: number) =>
      incidentApparatuses.slice(0, index).some(
        (apparatus) =>
          areIntervalsOverlapping(
            {
              start: incidentApparatuses[index].startDatetime ?? new Date(),
              end: incidentApparatuses[index].endDatetime ?? new Date(),
            },
            { start: apparatus.startDatetime ?? new Date(), end: apparatus.endDatetime ?? new Date() },
          ) && incidentApparatuses[index].apparatus?.id === apparatus.apparatus?.id,
      );
  }, [incidentApparatuses]);

  const checkEmployeeOverlap = useMemo(() => {
    return (apparatusIndex: number) =>
      incidentApparatuses[apparatusIndex].personnel
        .filter((employee) =>
          incidentApparatuses.slice(0, apparatusIndex).some(
            (prevApparatus) =>
              prevApparatus.personnel.some((e) => e.id === employee.id) &&
              areIntervalsOverlapping(
                {
                  start: incidentApparatuses[apparatusIndex].startDatetime ?? new Date(),
                  end: incidentApparatuses[apparatusIndex].endDatetime ?? new Date(),
                },
                { start: prevApparatus.startDatetime ?? new Date(), end: prevApparatus.endDatetime ?? new Date() },
              ),
          ),
        )
        .map((employee) => employee.name)
        .filter(Boolean);
  }, [incidentApparatuses]);

  const overlappingApparatus = useMemo(
    () => incidentApparatuses.filter((_, index) => checkApparatusOverlap(index)),
    [incidentApparatuses, checkApparatusOverlap],
  );

  const overlappingEmployees = useMemo(
    () => incidentApparatuses.flatMap((_, index) => checkEmployeeOverlap(index)),
    [incidentApparatuses, checkEmployeeOverlap],
  );

  const disabled =
    !isCloseIncident &&
    (!isActive ||
      overlappingApparatus.length > 0 ||
      overlappingEmployees.length > 0 ||
      incidentApparatuses.length === 0 ||
      !incidentApparatuses.every(
        (apparatus) =>
          apparatus.apparatus !== null &&
          apparatus.startDatetime !== null &&
          apparatus.endDatetime !== null &&
          apparatus.personnel.length > 0 &&
          (isAfter(apparatus.startDatetime, incidentStartDatetime) ||
            (isSameDay(apparatus.startDatetime, incidentStartDatetime) &&
              new Date(apparatus.startDatetime).getTime() === incidentStartDatetime.getTime())) &&
          isAfter(new Date(apparatus.endDatetime), apparatus.startDatetime) &&
          isAfter(new Date(apparatus.endDatetime), incidentStartDatetime),
      ));

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const response = await client.get<Employee[]>('employee/employees/');
        if (response.data) {
          const mappedEmployees = response.data.map((employee) => ({
            id: employee.id,
            name: employee.name,
          }));
          setPersonnelOptions(mappedEmployees);
        }
      } catch (error) {
        captureException(error);
      }
    };
    getEmployees();
  }, []);

  useEffect(() => {
    const getApparatuses = async () => {
      try {
        const response = await client.get<Apparatus[]>('organization/apparatus/');
        if (response.data) {
          setApparatusOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    getApparatuses();
  }, []);

  const personnelSelectOptions = useMemo(() => {
    return personnelOptions.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }, [personnelOptions]);

  const handleAddApparatus = () => {
    setIncidentApparatuses([
      ...incidentApparatuses,
      {
        apparatus: null,
        startDatetime: incidentStartDatetime,
        endDatetime: null,
        personnel: [],
        payCode: null,
        note: '',
      } as IncidentApparatus,
    ]);
  };

  const handleUpdateApparatus = (
    index: number,
    field: keyof IncidentApparatus,
    value: Apparatus | Date | { id: string | null; name: string | null }[] | string | null,
  ) => {
    const updatedApparatuses = [...incidentApparatuses];
    updatedApparatuses[index] = { ...updatedApparatuses[index], [field]: value };
    setIncidentApparatuses(updatedApparatuses);
  };

  const handleDeleteApparatus = (index: number) => {
    if (!isActive) return;
    const updatedApparatuses = incidentApparatuses.filter((_, i) => i !== index);
    setIncidentApparatuses(updatedApparatuses);
  };

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={open}
      handleOnClose={onClose}
      loading={false}
      showHeader
      headerTitle="Staffing Details"
      disableFooter
      noBorderOnHeader={false}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          height: '100%',
        },
      }}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '100%',
          px: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
        })}
      >
        <Box>
          {incidentApparatuses.length === 0 && (
            <Box
              sx={(theme) => ({
                justifyContent: 'center',
                alignItems: 'center',
                mt: '50%',
                textAlign: 'center',
                mb: theme.spacing(2),
              })}
            >
              <Box>
                <AddLater />
              </Box>
              <Typography
                variant="bodyXLSemibold"
                sx={(theme) => ({ color: theme.palette.stationGray[900], marginBottom: theme.spacing(1) })}
              >
                You can always come back <br /> and add apparatus later!
              </Typography>
            </Box>
          )}
          {incidentApparatuses.map((apparatusData, index) => (
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.common.white,
                border: `2px dashed ${theme.palette.stationGray[300]}`,
                borderRadius: '8px',
                px: theme.spacing(1),
                pb: theme.spacing(2),
                pt: theme.spacing(1),
                my: theme.spacing(1),
              })}
              key={index}
            >
              <Typography variant="bodyXLSemibold">Apparatus {index + 1}</Typography>
              {checkNapaWildfireCondition(apparatusData) && (
                <Alert severity="info" sx={(theme) => ({ my: theme.spacing(1) })}>
                  Warning: Pay code of this apparatus will be converted to CALFIRE since it is a Napa Wildfire condition with
                  specific apparatuses.
                </Alert>
              )}
              {checkApparatusOverlap(index) && (
                <Alert severity="error" sx={(theme) => ({ my: theme.spacing(1) })}>
                  Warning: This Apparatus already exists on the Incident. Make sure the Start Time + End Time do not overlap or
                  select a different Apparatus.
                </Alert>
              )}
              {checkEmployeeOverlap(index).length > 0 && (
                <Alert severity="error" sx={(theme) => ({ my: theme.spacing(1) })}>
                  Warning: The following employees have overlapping times in other apparatuses:{' '}
                  {checkEmployeeOverlap(index).join(', ')}
                </Alert>
              )}
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  typography: 'bodyLMedium',
                  mt: theme.spacing(2),
                })}
              >
                Apparatus
              </Box>
              <Box
                sx={(theme) => ({
                  display: 'block',
                  width: '100%',
                  mt: theme.spacing(1),
                })}
              >
                <Autocomplete
                  id="tags-outlined-apparatus"
                  options={apparatusOptions}
                  getOptionLabel={(option) => option.name || ''}
                  value={apparatusData.apparatus || null}
                  onChange={(_event, newValue) => handleUpdateApparatus(index, 'apparatus', newValue)}
                  renderInput={(params) => <TextField {...params} placeholder="Choose apparatus" />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={!isActive}
                />
              </Box>

              <Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[900],
                    typography: 'bodyLMedium',
                    mt: theme.spacing(1),
                  })}
                >
                  Start Time
                </Box>
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    typography: 'bodySRegular',
                    color: theme.palette.stationGray[500],
                  })}
                >
                  Time the Apparatus left from the Station to the Incident
                </Box>

                <Box
                  sx={(theme) => ({
                    width: '100%',
                    mt: theme.spacing(1),
                  })}
                >
                  <DatePickerComp
                    value={new Date(apparatusData.startDatetime)}
                    setValue={(newValue) => handleUpdateApparatus(index, 'startDatetime', newValue)}
                    openIconPosition="start"
                    placeholder="Choose date"
                    sxProps={{ width: '48%', border: '0px' }}
                    format="dd MMM, yyyy"
                    minDate={incidentStartDatetime}
                    disabled={!isActive}
                  />
                  <SimpleTimePicker
                    openIconPosition="start"
                    setValue={(newValue) => handleUpdateApparatus(index, 'startDatetime', newValue)}
                    sxProps={{ width: '48%', ml: '4%' }}
                    usePreciseTime
                    value={new Date(apparatusData.startDatetime)}
                    minTime={
                      apparatusData.startDatetime && isSameDay(apparatusData.startDatetime, incidentStartDatetime)
                        ? incidentStartDatetime
                        : undefined
                    }
                    disabled={!isActive}
                  />
                </Box>
              </Box>

              <Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[900],
                    typography: 'bodyLMedium',
                    mt: theme.spacing(1),
                  })}
                >
                  End Time
                </Box>
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    typography: 'bodySRegular',
                    color: theme.palette.stationGray[500],
                  })}
                >
                  Time the Apparatus returned to the Station from the Incident
                </Box>

                <Box
                  sx={(theme) => ({
                    width: '100%',
                    mt: theme.spacing(1),
                  })}
                >
                  <DatePickerComp
                    value={apparatusData.endDatetime ? new Date(apparatusData.endDatetime) : null}
                    setValue={(newValue) => {
                      if (newValue && !apparatusData.endDatetime && isSameDay(newValue, apparatusData.startDatetime)) {
                        // set time to same as timeOut when selecting data on the same day
                        const newTimeAvailable = new Date(newValue);
                        newTimeAvailable.setHours(apparatusData.startDatetime.getHours());
                        newTimeAvailable.setMinutes(apparatusData.startDatetime.getMinutes());
                        handleUpdateApparatus(index, 'endDatetime', newTimeAvailable);
                      } else {
                        handleUpdateApparatus(index, 'endDatetime', newValue);
                      }
                    }}
                    openIconPosition="start"
                    placeholder="Choose date"
                    sxProps={{ width: '48%', border: '0px' }}
                    format="dd MMM, yyyy"
                    minDate={new Date(apparatusData.startDatetime)}
                    disabled={!isActive}
                  />
                  <SimpleTimePicker
                    openIconPosition="start"
                    value={apparatusData.endDatetime ? new Date(apparatusData.endDatetime) : null}
                    setValue={(newValue) => handleUpdateApparatus(index, 'endDatetime', newValue)}
                    sxProps={{ width: '48%', ml: '4%' }}
                    usePreciseTime
                    minTime={
                      apparatusData.startDatetime &&
                      apparatusData.endDatetime &&
                      isSameDay(apparatusData.startDatetime, apparatusData.endDatetime)
                        ? apparatusData.startDatetime
                        : undefined
                    }
                    disabled={!isActive}
                  />
                </Box>
              </Box>

              <Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[900],
                    typography: 'bodyLMedium',
                    mt: theme.spacing(1),
                  })}
                >
                  Duration
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: theme.spacing(1),
                  })}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.stationGray[200]}`,
                      borderRadius: theme.spacing(1),
                      p: '10px 13px 10px',
                      width: '50%',
                      cursor: 'pointer',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      typography: 'bodySRegular',
                      backgroundColor: theme.palette.stationGray[100],
                    })}
                  >
                    {apparatusData.endDatetime && apparatusData.startDatetime && (
                      <Box>{differenceInHours(apparatusData.endDatetime, apparatusData.startDatetime)}</Box>
                    )}
                    <Box
                      sx={(theme) => ({
                        color: theme.palette.stationGray[500],
                        '& svg': {
                          color: theme.palette.stationGray[400],
                        },
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(0.5),
                      })}
                    >
                      HRS
                    </Box>
                  </Box>
                  <Box
                    data-cy="duration-minutes-select"
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.stationGray[200]}`,
                      borderRadius: theme.spacing(1),
                      p: '10px 13px 10px',
                      width: '50%',
                      cursor: 'pointer',
                      display: 'flex',
                      marginLeft: theme.spacing(2),
                      justifyContent: 'space-between',
                      typography: 'bodySRegular',
                      backgroundColor: theme.palette.stationGray[100],
                    })}
                  >
                    {apparatusData.endDatetime && apparatusData.startDatetime && (
                      <Box>{differenceInMinutes(apparatusData.endDatetime, apparatusData.startDatetime) % 60}</Box>
                    )}

                    <Box
                      sx={(theme) => ({
                        color: theme.palette.stationGray[500],
                        '& svg': {
                          color: theme.palette.stationGray[400],
                        },
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(0.5),
                      })}
                    >
                      MIN
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  typography: 'bodyLMedium',
                  mt: theme.spacing(2),
                })}
              >
                Personnel
              </Box>

              <Box
                sx={(theme) => ({
                  display: 'block',
                  width: '100%',
                  mt: theme.spacing(1),
                })}
              >
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="tags-outlined-personnel"
                  options={personnelSelectOptions.map((option) => ({ id: option.value, name: option.label }))}
                  getOptionLabel={(option) => option.name || ''}
                  value={apparatusData.personnel || []}
                  disableCloseOnSelect
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_event, newValue) => handleUpdateApparatus(index, 'personnel', newValue)}
                  renderInput={(params) => <TextField {...params} placeholder="Choose employees" />}
                  disabled={!isActive}
                />
              </Box>

              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  typography: 'bodyLMedium',
                  mt: theme.spacing(2),
                })}
              >
                Note
              </Box>

              <Box sx={(theme) => ({ mt: theme.spacing(1) })}>
                <TextField
                  fullWidth
                  minRows={2}
                  multiline
                  maxRows={3}
                  value={apparatusData.note}
                  onChange={(event) => handleUpdateApparatus(index, 'note', event.target.value)}
                  disabled={!isActive}
                />
              </Box>
              {isActive && (
                <Box
                  onClick={() => handleDeleteApparatus(index)}
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(0.5),
                    mt: theme.spacing(2),
                    cursor: isActive ? 'pointer' : 'default',
                  })}
                >
                  <TrashIcon />
                  <Typography variant="bodySRegular">Remove apparatus</Typography>
                </Box>
              )}
            </Box>
          ))}
          {isActive && (
            <Button
              variant="contained"
              buttonType="tertiary"
              disableFocusRipple={true}
              disableRipple={true}
              disabled={!isActive}
              onClick={handleAddApparatus}
              sx={(theme) => ({
                width: '100%',
                borderRadius: theme.spacing(0.75),
                my: theme.spacing(1),
                '&:hover': {
                  backgroundColor: theme.palette.common.white,
                },
              })}
            >
              + Add Apparatus
            </Button>
          )}
        </Box>
        {isActive && (
          <Box
            sx={(theme) => ({
              width: '100%',
              justifyContent: 'center',
              mb: theme.spacing(2),
              display: 'flex',
              gap: theme.spacing(1),
              marginTop: 'auto', // Pushes the buttons to the bottom
            })}
          >
            {!isCloseIncident && (
              <Button
                variant="contained"
                buttonType="secondary"
                disableFocusRipple={true}
                disableRipple={true}
                disabled={!isActive}
                onClick={() => {
                  handleSave();
                }}
                sx={(theme) => ({
                  width: '100%',
                  borderRadius: theme.spacing(0.75),
                })}
              >
                Add later
              </Button>
            )}

            <Button
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              disabled={disabled}
              onClick={() => {
                handleSave();
              }}
              sx={(theme) => ({
                width: '100%',
                borderRadius: theme.spacing(0.75),
              })}
            >
              {isCloseIncident ? 'Save + Close' : 'Save'}
            </Button>
          </Box>
        )}
      </Box>
    </GenericDrawerOrModal>
  );
};
