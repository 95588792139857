import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button, ExclamationMarkIcon24, theme } from '@stationwise/component-module';

interface WarningModalProps {
  open: boolean;
  onClose: () => void;
  uploadType: 'accruals' | 'time-off';
  onSubmit: () => void;
  isSubmitting: boolean;
  isSingleEmployeeChange: boolean;
  hasExistingTimeOffChanges: boolean;
}

export const WarningModal = ({
  open,
  onClose,
  uploadType,
  onSubmit,
  isSubmitting,
  isSingleEmployeeChange,
  hasExistingTimeOffChanges,
}: WarningModalProps) => {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={(theme) => ({
            '& svg path': { stroke: theme.palette.stationOrange[500] },
            borderRadius: '80px',
            borderColor: theme.palette.stationGray[50],
            boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
            width: '56px',
            height: '56px',
            p: theme.spacing(2),
            my: theme.spacing(2),
          })}
        >
          <ExclamationMarkIcon24 />
        </Box>
      </Box>
      <DialogTitle id="alert-dialog-title" sx={{ typography: 'bodyXLSemibold' }}>
        {'Are you sure you want to submit the changes?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ typography: 'bodyMRegular', color: theme.palette.text.primary }}>
          <>
            This is a big change and will <br />
            {isSingleEmployeeChange ? `• Overwrite data for this user` : `• Overwrite data for multiple users`}
            <br />
            {uploadType === 'accruals'
              ? `• Impact whether they can request certain time off.`
              : hasExistingTimeOffChanges
                ? `• Cancel their future approved time offs.`
                : ''}
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button buttonType="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit} autoFocus disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Yes, submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
