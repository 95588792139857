import { useCallback, useEffect } from 'react';
import { ShiftPlanAction } from '@stationwise/share-types';
import { toggleApparatusMode, removeApparatus } from '../../helpers/crudApparatus';
import { removePosition } from '../../helpers/crudPosition';
import { removeStation } from '../../helpers/crudStation';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { ShiftPlanDialog, ShiftPlanDialogActions, WarningMessage } from '../ShiftPlanDialog';
import { MoveApparatusForm } from './MoveApparatusForm';
import { UpsertApparatusForm } from './UpsertApparatusForm';
import { UpsertPositionForm } from './UpsertPositionForm';
import { UpsertStationForm } from './UpsertStationForm';

export const StructDialogs = () => {
  const { stations, setStations, selectedStruct, selectedAction, setSelectedAction } = useShiftPlanContext();
  const { station: selectedStation, apparatus: selectedApparatus, position: selectedPosition } = selectedStruct;

  const isToggleApparatusModeWarningSkipped = !!(
    selectedAction === ShiftPlanAction.TOGGLE_APPARATUS_MODE &&
    (selectedApparatus?.isReserved || selectedApparatus?.positions.every((p) => !p.employees.length))
  );

  const onRemoveStation = () => {
    setStations(removeStation(stations, selectedStruct));
    setSelectedAction(null);
  };

  const onToggleApparatusMode = useCallback(() => {
    setStations(toggleApparatusMode(stations, selectedStruct));
    setSelectedAction(null);
  }, [stations, setStations, selectedStruct, setSelectedAction]);

  const onRemoveApparatus = () => {
    setStations(removeApparatus(stations, selectedStruct));
    setSelectedAction(null);
  };

  const onConfirmEditPosition = () => {
    setSelectedAction(ShiftPlanAction.UPSERT_POSITION);
  };

  const onRemovePosition = () => {
    setStations(removePosition(stations, selectedStruct));
    setSelectedAction(null);
  };

  useEffect(() => {
    isToggleApparatusModeWarningSkipped && onToggleApparatusMode();
  }, [isToggleApparatusModeWarningSkipped, onToggleApparatusMode]);

  return (
    <>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_STATION}>
        <UpsertStationForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_STATION}>
        <WarningMessage
          primaryText={`You are about to remove ${selectedStation?.stationName}.`}
          secondaryText="All assigned employees of all teams will be moved to the floater station."
        />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onRemoveStation} />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_APPARATUS}>
        <UpsertApparatusForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog
        action={ShiftPlanAction.TOGGLE_APPARATUS_MODE}
        open={selectedAction === ShiftPlanAction.TOGGLE_APPARATUS_MODE && !isToggleApparatusModeWarningSkipped}
      >
        <WarningMessage primaryText={`You are about to put ${selectedApparatus?.name} on reserve mode.`} />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onToggleApparatusMode} />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.MOVE_APPARATUS}>
        <MoveApparatusForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_APPARATUS}>
        <WarningMessage
          primaryText={`You are about to remove ${selectedApparatus?.name}.`}
          secondaryText="All assigned employees of all teams will be moved to the floater station."
        />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onRemoveApparatus} />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.UPSERT_POSITION}>
        <UpsertPositionForm />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.WARN_EDIT_POSITION}>
        <WarningMessage
          primaryText="To edit a position it must be empty."
          secondaryText="All assigned employees of all teams will be moved to the floater station."
        />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onConfirmEditPosition} />
      </ShiftPlanDialog>
      <ShiftPlanDialog action={ShiftPlanAction.REMOVE_POSITION}>
        <WarningMessage
          primaryText={`You are about to remove ${selectedPosition?.rank.name} position from ${selectedApparatus?.name}`}
          secondaryText="All assigned employees of all teams will be moved to the floater station."
        />
        <ShiftPlanDialogActions saveText="Confirm" onSave={onRemovePosition} />
      </ShiftPlanDialog>
    </>
  );
};
