import { ReactComponent as ArrowUpwardSymbol } from '@material-symbols/svg-400/outlined/arrow_upward.svg';
import { Box, Button } from '@mui/material';
import { isEqual } from 'date-fns';
import { getEmployeeSplitReferenceKey, getEmployeeSplitTradeKey } from '@stationwise/shift-summary-helper';
import { SvgIcon } from '../../../SvgIcon';
import { mergeSplits } from './actions';
import { SplitProps } from './types';

type MergeSplitsButtonGroupProps = SplitProps;

export const MergeSplitsButtonGroup = (props: MergeSplitsButtonGroupProps) => {
  const prevSplit = props.splits[props.index];
  const nextSplit = props.splits[props.index + 1];
  const prevSplitStatus = props.splitStatuses[props.index];
  const nextSplitStatus = props.splitStatuses[props.index + 1];
  if (!prevSplit || !nextSplit || prevSplit.endDateTime < nextSplit.startDateTime) {
    return null;
  }

  const areSplitReferencesEqual = getEmployeeSplitReferenceKey(prevSplit) === getEmployeeSplitReferenceKey(nextSplit);

  const checkAreSplitsMergeable = (direction?: 'UP' | 'DOWN') => {
    const mergedSplit = direction === 'UP' ? prevSplit : nextSplit;
    return (
      !prevSplitStatus.errors.get('DURATION') &&
      !nextSplitStatus.errors.get('DURATION') &&
      prevSplit.reference.type === nextSplit.reference.type &&
      getEmployeeSplitTradeKey(prevSplit) === getEmployeeSplitTradeKey(nextSplit) &&
      isEqual(prevSplit.endDateTime, nextSplit.startDateTime) &&
      prevSplit.startDateTime >= mergedSplit.minStartDateTime &&
      nextSplit.endDateTime <= mergedSplit.maxEndDateTime
    );
  };

  const renderMergeButton = (direction?: 'UP' | 'DOWN') => {
    return (
      <Button
        variant="outlined"
        disabled={!checkAreSplitsMergeable(direction)}
        sx={{ flex: 1, gap: 1, p: '9px 16px', textTransform: 'none' }}
        onClick={() => mergeSplits(props, direction)}
      >
        {direction && (
          <SvgIcon
            component={ArrowUpwardSymbol}
            sx={{ fontSize: '20px', transform: direction === 'DOWN' ? 'rotate(180deg)' : undefined }}
          />
        )}
        {!direction && 'Merge'}
        {direction === 'UP' && 'Merge Up'}
        {direction === 'DOWN' && 'Merge Down'}
      </Button>
    );
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      {areSplitReferencesEqual && renderMergeButton()}
      {!areSplitReferencesEqual && renderMergeButton('UP')}
      {!areSplitReferencesEqual && renderMergeButton('DOWN')}
    </Box>
  );
};
