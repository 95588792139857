import { Box, CircularProgress } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@stationwise/component-module';

interface CancelRequestProps {
  handleOnClose: Dispatch<SetStateAction<boolean>>;
  handleRequest: () => void;
  isOptOut?: boolean;
  isLoading: boolean;
}

export const CancelRequest = ({ handleOnClose, handleRequest, isOptOut, isLoading }: CancelRequestProps) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        p: theme.spacing(2),
        justifyContent: 'center',
      })}
    >
      <Box
        sx={(theme) => ({
          width: '100%',
          mb: theme.spacing(2),
          mt: theme.spacing(1),
        })}
      >
        <Button
          disableFocusRipple={true}
          disableRipple={true}
          variant="contained"
          buttonType="tertiary"
          onClick={() => handleOnClose(false)}
          sx={(theme) => ({
            background: theme.palette.common.white,
            color: theme.palette.common.black,
            width: '100%',
            borderWidth: theme.spacing(0.1),
            textTransform: 'none',
            borderRadius: theme.spacing(0.75),
          })}
        >
          <Box component="span" sx={{ padding: '9px 17px', typography: 'bodyMMedium', fontSize: '16px', fontWeight: 500 }}>
            {isOptOut ? 'No, I want to keep the opt out' : 'No, I want to keep the request'}
          </Box>
        </Button>
      </Box>
      <Box sx={(theme) => ({ width: '100%', mb: theme.spacing(1) })}>
        <Button
          variant="contained"
          disableFocusRipple={true}
          disableRipple={true}
          disabled={isLoading}
          onClick={handleRequest}
          sx={(theme) => ({
            background: theme.palette.common.black,
            width: '100%',
            textTransform: 'none',
            borderRadius: theme.spacing(0.75),
          })}
        >
          {isLoading && <CircularProgress color="inherit" size="2rem" />}
          <Box component="span" sx={isLoading ? visuallyHidden : { padding: '9px 17px', typography: 'bodyMMedium' }}>
            {isOptOut ? 'Yes, cancel the opt out' : 'Yes, cancel this request'}
          </Box>
        </Button>
      </Box>
    </Box>
  );
};
