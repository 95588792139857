import { Theme, useTheme } from '@mui/material';
import { EyeClosedIcon20, XCircleIcon20, EyeIcon20 } from '@stationwise/component-module';
import { InfoBanner, InfoBannerProps } from './InfoBanner';

const getCorrectInfoBanner = (type: string, amount: number, theme: Theme): InfoBannerProps | null => {
  const buttonText = 'Resend';

  switch (type) {
    case 'not-seeing-not-completed':
      return {
        Icon: XCircleIcon20,
        Text: (
          <span>
            <strong>{amount}</strong> people haven&apos;t seen or completed
          </span>
        ),
        buttonType: 'tertiary',
        buttonText,
        textColor: theme.palette.stationGray[900],
        backgroundColor: theme.palette.stationLightBlue[100],
      };
    case 'not-seeing':
      return {
        Icon: EyeClosedIcon20,
        Text: (
          <span>
            <strong>{amount}</strong> people <strong>haven&apos;t seen</strong> this message yet
          </span>
        ),
        buttonType: 'primary',
        buttonText,
        textColor: theme.palette.stationGray[900],
        backgroundColor: theme.palette.common.white,
      };
    case 'seeing-not-completed':
      return {
        Icon: EyeIcon20,
        Text: (
          <span>
            <strong>{amount}</strong> have seen but <strong>haven&apos;t completed</strong>
          </span>
        ),
        buttonType: 'tertiary',
        buttonText,
        textColor: theme.palette.stationGray[900],
        backgroundColor: theme.palette.common.white,
      };
    default:
      return null;
  }
};

interface InfoBannerAdapterProps {
  type: string;
  amount: number;
  onClick?: () => void;
  disableButton?: boolean;
  isLoading?: boolean;
}

export const InfoBannerAdapter = ({ type, amount, onClick, disableButton, isLoading }: InfoBannerAdapterProps) => {
  const theme = useTheme();
  const infoBannerProps = getCorrectInfoBanner(type, amount, theme);

  if (infoBannerProps === null) {
    return null;
  }

  return (
    <InfoBanner
      Icon={infoBannerProps.Icon}
      Text={infoBannerProps.Text}
      buttonType={infoBannerProps.buttonType}
      buttonText={infoBannerProps.buttonText}
      onClick={onClick}
      disableButton={disableButton}
      textColor={infoBannerProps.textColor}
      backgroundColor={infoBannerProps.backgroundColor}
      isLoading={isLoading}
    />
  );
};
