import { Box, Typography } from '@mui/material';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SetStateAction, useState, Dispatch } from 'react';
import { GenericDrawerOrModal, theme } from '@stationwise/component-module';
import { ShiftTrade } from '@stationwise/share-types';

interface TimeSelectorProps {
  openTimeSelect: boolean;
  setOpenTimeSelect: (isOpen?: boolean) => void;
  shiftTrade: ShiftTrade;
  onClose: () => void;
  onConfirmed: () => void;
  setDebtForgiven: Dispatch<SetStateAction<number>>;
}

export const TimeSelector = ({ openTimeSelect, setOpenTimeSelect, shiftTrade, setDebtForgiven }: TimeSelectorProps) => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);

  function handleButtonClick(open: boolean) {
    const value = (selectedTime?.getHours() ?? 0) + (selectedTime?.getMinutes() ?? 0) / 60;
    setDebtForgiven(value);
    setOpenTimeSelect(open);
  }

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={openTimeSelect}
      handleOnClose={() => setOpenTimeSelect(false)}
      loading={false}
      showHeader={true}
      disableFooter={true}
      noBorderOnHeader={true}
      sxProps={() => ({ '& .MuiDrawer-paper': { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } })}
    >
      <div>
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            width: '100%',
          })}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MultiSectionDigitalClock
              sx={{
                justifyContent: 'center',
                borderBottom: 'none',
                '.MuiMenuItem-root': { backgroundColor: theme.palette.common.white },
                '.MuiMenuItem-root.Mui-selected': {
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                },
                '.MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                },
                '.MuiButtonBase-root': {
                  borderRadius: '8px',
                  fontSize: '20px',
                },
                '.MuiMultiSectionDigitalClock-root': {
                  backgroundColor: theme.palette.common.white,
                  overflowY: 'hidden',
                },
                '.MuiList-root': {
                  overflowY: 'scroll',
                  borderLeft: 'none',
                  marginX: '30px',
                },
                '.MuiList-root.MuiList-padding': { borderLeft: 'none' },
              }}
              value={selectedTime}
              onChange={(value) => {
                if (
                  value &&
                  !(value.getHours() === 0 && value.getMinutes() === 0) &&
                  value.getHours() + value.getMinutes() / 60 <= (shiftTrade.owedHours ?? 0)
                ) {
                  setSelectedTime(value);
                }
              }}
              shouldDisableTime={(timeValue) => {
                const hours = timeValue.getHours() + timeValue.getMinutes() / 60;
                return hours < 0 || hours > (shiftTrade.owedHours ?? 0);
              }}
              ampm={false}
              timeSteps={{ hours: 1, minutes: 15 }}
            />
          </LocalizationProvider>

          {/* Button 2*/}
          <Box
            sx={(theme) => ({
              border: `solid 1px ${theme.palette.stationGray[300]}`,
              boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
              borderRadius: '8px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: '9px 17px',
              backgroundColor: theme.palette.stationGray[900],
              mt: theme.spacing(3),
            })}
            onClick={() => {
              if (selectedTime) {
                handleButtonClick(false);
              }
            }}
          >
            <Typography variant="bodyMSemibold" sx={{ color: theme.palette.common.white }}>
              Save
            </Typography>
          </Box>
        </Box>
      </div>
    </GenericDrawerOrModal>
  );
};
