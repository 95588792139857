import { useTheme } from '@mui/material';
import { GenericDrawerOrModal, FeedbackMessage } from '@stationwise/component-module';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';

interface AdditionalPaidTimeResponseProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  message: string;
}

export const AdditionalPaidTimeResponse = ({ onClose, error, message }: AdditionalPaidTimeResponseProps) => {
  const theme = useTheme();
  const status = error ? 'error' : 'success';
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      loading={false}
      drawerOpen={true}
      handleOnClose={() => onClose(false)}
      footerButtons={[
        {
          label: 'Go back to calendar',
          onClick: () => onClose(false),
          backgroundColor: theme.palette.common.black,
        },
      ]}
      paddingBottom={iosWebViewPaddingBottom}
    >
      <FeedbackMessage status={status} firstMessage={message} />
    </GenericDrawerOrModal>
  );
};
