import { Box } from '@mui/material';
import { Button, Modal, useRosterContext } from '@stationwise/component-module';
import { WarningModal } from '../../../../components/Common';

export const UnplannedAssignmentWarning = () => {
  const { shiftSummaryHelper, setShiftSummaryHelper } = useRosterContext();
  const { unplannedAssignees } = shiftSummaryHelper;

  const close = () => setShiftSummaryHelper({ ...shiftSummaryHelper, unplannedAssignees: [] });
  const setOpen = (open: boolean) => !open && close();

  return (
    <Modal open={unplannedAssignees.length > 0} setOpen={setOpen}>
      <WarningModal setModalOpen={setOpen} onConfirm={close} hideButtons>
        <Box sx={{ maxWidth: '416px' }}>
          <Box sx={{ mb: 2, typography: 'bodyXXLSemibold' }}>
            {unplannedAssignees.length > 1 ? 'Unplanned people' : 'Unplanned person'}
          </Box>
          {unplannedAssignees.length > 1 && (
            <Box
              sx={(theme) => ({
                typography: 'bodyMRegular',
                '& ul': {
                  display: 'inline-flex',
                  flexDirection: 'column',
                  listStyleType: 'disc',
                  m: 0,
                  p: theme.spacing(0, 0, 0, 2),
                },
                '& li': { m: theme.spacing(0.5, 0, 0, 0), textAlign: 'left' },
              })}
            >
              These people were not added back to the roster because they do not have any planned assignments.
              <ul>
                {unplannedAssignees.map((assignee, i) => (
                  <li key={i}>{assignee.name}</li>
                ))}
              </ul>
            </Box>
          )}
          {unplannedAssignees.length === 1 && (
            <Box sx={{ typography: 'bodyMRegular' }}>
              {`${unplannedAssignees[0].name} was not added back to the roster because they do not have any planned assignments.`}
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <Button buttonType="primary" sx={() => ({ width: '50%' })} onClick={close}>
              Confirm
            </Button>
          </Box>
        </Box>
      </WarningModal>
    </Modal>
  );
};
