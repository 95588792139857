import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { LottieLogo, Button, SearchInput, SnackbarService, useAuthUserCapabilities } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { WorkGroupMember, WorkGroupPreview } from '@stationwise/share-types';
import { EditWorkGroupModal } from './WorkGroups/EditWorkGroupModal';
import { ListMembers } from './WorkGroups/ListMembers';
import { ListWorkGroups } from './WorkGroups/ListWorkGroups';
import { RemoveWorkGroupModal } from './WorkGroups/RemoveWorkGroupModal';

interface WorkGroupsMainProps {
  workGroupsView: WorkGroupPreview[];
}

export const WorkGroupsMain = ({ workGroupsView }: WorkGroupsMainProps) => {
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [workGroups, setWorkGroups] = useState<WorkGroupPreview[]>(workGroupsView);
  const capabilities = useAuthUserCapabilities();
  const canManageWorkGroups = capabilities.MANAGE_WORK_GROUPS;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [title, setTitle] = useState('New work group');
  const [selectedWorkGroupId, setSelectedWorkGroupId] = useState<number | null>(null);
  const [selectedWorkGroup, setSelectedWorkGroup] = useState<WorkGroupPreview | undefined>();

  useEffect(() => {
    const selected = workGroups.find((wg) => wg.id === selectedWorkGroupId);
    setSelectedWorkGroup(selected);
  }, [selectedWorkGroupId, workGroups]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      try {
        const params = {
          ...(deferredSearchInput && { search: deferredSearchInput }),
        };
        const response = await client.get('organization/workgroups/detailed-search-list/', {
          params,
          signal: abortControllerRef.current.signal,
        });
        setWorkGroups(response.data);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    fetchData();
  }, [deferredSearchInput]);

  const sendRemoveWorkGroupRequest = async () => {
    try {
      if (!selectedWorkGroupId) return;
      await client.delete(`/organization/workgroups/${selectedWorkGroupId}/`, {});
      removeWorkGroup();
      setSelectedWorkGroupId(null);
      SnackbarService.notify({
        content: `${selectedWorkGroup?.name} team has been removed.`,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'An error occured while removing work group',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const removeWorkGroup = () => {
    setWorkGroups((prevGroups) => prevGroups.filter((group) => group.id !== selectedWorkGroupId));
  };

  const handleRemove = () => {
    sendRemoveWorkGroupRequest();
    setShowRemoveModal(false);
  };

  const updateMemberCount = (id: number, removedCount: number, members: WorkGroupMember[]) => {
    setWorkGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === id
          ? {
              ...group,
              membersCount: group.membersCount - removedCount,
              firstFourMembers: group.firstFourMembers.filter((m) => members.some((member) => member.id === m.id)),
            }
          : group,
      ),
    );
  };

  const updateWorkGroup = (name: string, description: string) => {
    setWorkGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === selectedWorkGroupId
          ? {
              ...group,
              name: name,
              description: description,
            }
          : group,
      ),
    );
  };

  const sendEditWorkGroupRequest = async (name: string, description: string) => {
    try {
      if (!selectedWorkGroupId) return;
      await client.patch(`/organization/workgroups/${selectedWorkGroupId}/`, {
        name: name,
        description: description,
      });
      updateWorkGroup(name, description);
      SnackbarService.notify({
        content: 'Work group has been successfully edited.',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'An error occured while updating work group',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const createWorkGroup = (newWorkGroup: WorkGroupPreview) => {
    setWorkGroups((prev) => [newWorkGroup, ...prev]);
    setSelectedWorkGroupId(newWorkGroup.id);
    setSelectedWorkGroup(newWorkGroup);
  };

  const sendCreateWorkGroupRequest = async (name: string, description: string) => {
    try {
      const response = await client.post(`/organization/workgroups/`, {
        name: name,
        description: description,
      });
      createWorkGroup(response.data);
      SnackbarService.notify({
        content: 'Work group has been successfully created.',
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'An error occured while creating work group.',
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const handleEdit = (name: string, description: string) => {
    sendEditWorkGroupRequest(name, description);
    setShowEditModal(false);
  };

  const handleCreate = (name: string, description: string) => {
    sendCreateWorkGroupRequest(name, description);
    setShowEditModal(false);
  };

  const header = `Do you really wish to remove ${selectedWorkGroup?.name ?? ''} work group?`;
  const subtext =
    'This cannot be undone. Prior messages will be unaffected, but you will need to create a new work group to message this set of users in the future.';

  return (
    <Box>
      <EditWorkGroupModal
        handleEdit={handleEdit}
        handleCreate={handleCreate}
        name={selectedWorkGroup?.name}
        description={selectedWorkGroup?.description}
        title={title}
        setTitle={setTitle}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      {selectedWorkGroup && (
        <RemoveWorkGroupModal
          handleRemove={handleRemove}
          showModal={showRemoveModal}
          setShowModal={setShowRemoveModal}
          header={header}
          subtext={subtext}
        />
      )}
      <Box
        sx={(theme) => ({
          boxShadow: 'none',
          margin: theme.spacing(1),
        })}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '50%' }}>
            <SearchInput
              value={searchInput}
              setValue={setSearchInput}
              color="white"
              placeHolder={'Search for people or groups'}
            />
          </Box>
          {canManageWorkGroups && (
            <Button
              variant="contained"
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                borderRadius: 1.5,
              })}
              onClick={() => setShowEditModal(true)}
            >
              + Create new work group
            </Button>
          )}
        </Box>
        {!isLoading && (
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
            <Box sx={{ width: '50%' }}>
              <ListWorkGroups
                workGroups={workGroups}
                setShowEditModal={setShowEditModal}
                setShowRemoveModal={setShowRemoveModal}
                setTitle={setTitle}
                setSelectedWorkGroupId={setSelectedWorkGroupId}
                selectedWorkGroupId={selectedWorkGroupId}
              />
            </Box>
            <Box sx={{ width: '50%' }}>
              <ListMembers updateMemberCount={updateMemberCount} workGroup={selectedWorkGroup} />
            </Box>
          </Box>
        )}
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo height="200px" width="200px" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
