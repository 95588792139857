import { Box, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { getTooltipProps, SelectItem } from '@stationwise/component-module';
import { getFilterAndSortOptions } from './filterAndSortOptions';

interface TiebreakerListProps {
  selectedSortRule: string;
  selectedTiebreakerRules: SelectItem[];
  setSelectedTiebreakerRules: (selected: SelectItem[]) => void;
  dialogOpen: boolean;
}

function not(a: SelectItem[], b: SelectItem[]) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a: SelectItem[], b: SelectItem[]) {
  return a.filter((value) => b.includes(value));
}

export const TiebreakerList = ({
  selectedSortRule,
  selectedTiebreakerRules,
  setSelectedTiebreakerRules,
  dialogOpen,
}: TiebreakerListProps) => {
  const { tiebreakerRules } = getFilterAndSortOptions();
  const [availableTiebreakerRules, setAvailableTiebreakerRules] = useState<SelectItem[]>(tiebreakerRules);
  const [checked, setChecked] = useState<SelectItem[]>([]);
  const [left, setLeft] = useState<SelectItem[]>(availableTiebreakerRules.filter((tr) => tr.value !== selectedSortRule));
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, selectedTiebreakerRules);

  useEffect(() => {
    if (!dialogOpen) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  useEffect(() => {
    setAvailableTiebreakerRules(tiebreakerRules.filter((tiebreaker) => tiebreaker.value !== selectedSortRule));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortRule]);

  useEffect(() => {
    const selectedTiebreakerRuleValues = selectedTiebreakerRules.map((tr) => tr.value);
    setLeft(
      availableTiebreakerRules.filter((tr) => tr.value !== selectedSortRule && !selectedTiebreakerRuleValues.includes(tr.value)),
    );
    const availableValues = availableTiebreakerRules.map((tb) => tb.value);
    setChecked((prev) => prev.filter((item) => availableValues.includes(item.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTiebreakerRules, availableTiebreakerRules]);

  useEffect(() => {
    setSelectedTiebreakerRules(selectedTiebreakerRules.filter((item) => item.value !== selectedSortRule));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortRule]);

  const reset = () => {
    setAvailableTiebreakerRules(tiebreakerRules);
  };

  const handleToggle = (item: SelectItem) => () => {
    const currentIndex = checked.indexOf(item);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSelectedTiebreakerRules(selectedTiebreakerRules.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setSelectedTiebreakerRules(selectedTiebreakerRules.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setSelectedTiebreakerRules(not(selectedTiebreakerRules, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(selectedTiebreakerRules));
    setSelectedTiebreakerRules([]);
  };

  const customList = (items: SelectItem[], showTitle?: boolean) => (
    <Paper sx={{ width: 220, height: 230, overflow: 'auto' }}>
      {showTitle && (
        <Box
          sx={{
            typography: 'bodyXSSemibold',
            textAlign: 'center',
            mt: 2,
          }}
        >
          Tiebreakers
        </Box>
      )}
      <List dense component="div" role="list">
        {items.map((item: SelectItem) => {
          return (
            <ListItemButton key={item.value} role="listitem" onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox checked={checked.includes(item)} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <Tooltip arrow title={item.helperText || ''} placement="left" slotProps={getTooltipProps()}>
                <ListItemText primary={item.label} />
              </Tooltip>
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <>
      <Box
        sx={(theme) => ({
          typography: 'bodyXSRegular',
          mb: 2,
          color: theme.palette.stationGray[500],
          background: theme.palette.stationGray[100],
          p: 1,
          borderRadius: '12px',
          textAlign: 'center',
        })}
      >
        Select tiebreakers in the order you want to apply them
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box>{customList(left)}</Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ alignItems: 'center' }}>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={selectedTiebreakerRules.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Box>
        </Box>
        <Box>{customList(selectedTiebreakerRules, true)}</Box>
      </Box>
    </>
  );
};
