import { Box, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlankCard, GenericDrawerOrModal, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftTrade, ShiftTradesView } from '@stationwise/share-types';
import { ShiftTradeCard } from './ShiftTradeCard';
import { ShiftTradeForgive } from './ShiftTradeForgive';

interface NextShiftTradesProps {
  shiftTrades: ShiftTradesView | null;
  forceRefetchShiftTrades: () => void;
}

export const NextShiftTrades = ({ shiftTrades, forceRefetchShiftTrades }: NextShiftTradesProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [selectedShiftTrade, setSelectedShiftTrade] = useState<ShiftTrade | null>(null);

  const handleOnClose = () => {
    setSelectedShiftTrade(null);
  };

  const setConfirmedDrawer = () => {
    forceRefetchShiftTrades();
  };

  return (
    <BlankCard type="passive" sxProps={{ border: 'none', padding: 2, boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="bodyLSemibold"
          sx={{
            color: theme.palette.stationGray[900],
          }}
        >
          Shift trades
        </Typography>
      </Box>
      {shiftTrades && shiftTrades.receivedShiftTrades && shiftTrades.receivedShiftTrades.length > 0 && (
        <Box>
          <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[500] }}>
            Requests from others
          </Typography>
          <Box sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}>
            {shiftTrades.receivedShiftTrades.map((shiftTrade, index) => (
              <Box
                data-cy="trade-requests-card"
                onClick={() => {
                  navigate(`/app/trade-request?trade_request_id=${shiftTrade.id}`);
                  window.scrollTo(0, 0);
                }}
                key={index}
                sx={{ mt: theme.spacing(1) }}
              >
                <ShiftTradeCard shiftTrade={shiftTrade} isNextShiftTrade />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {shiftTrades && shiftTrades.sentShiftTrades && shiftTrades.sentShiftTrades.length > 0 && (
        <Box>
          <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[500] }}>
            My pending requests
          </Typography>
          <Box sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}>
            {shiftTrades.sentShiftTrades.map((shiftTrade, index) => (
              <Box key={index} sx={{ mt: theme.spacing(1) }}>
                <ShiftTradeCard shiftTrade={shiftTrade} isNextShiftTrade />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {shiftTrades && shiftTrades.waitingApproval && shiftTrades.waitingApproval.length > 0 && (
        <Box>
          <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[500] }}>
            Pending Approval
          </Typography>
          <Box sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}>
            {shiftTrades.waitingApproval.map((shiftTrade, index) => (
              <Box key={index} sx={{ mt: theme.spacing(1) }}>
                <ShiftTradeCard shiftTrade={shiftTrade} isNextShiftTrade />
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {shiftTrades && shiftTrades.nextShiftTrade && shiftTrades.nextShiftTrade.startDate && (
        <Box>
          <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[500] }}>
            Next shift trade
          </Typography>
          <Box sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}>
            <ShiftTradeCard shiftTrade={shiftTrades.nextShiftTrade} isNextShiftTrade />
          </Box>
        </Box>
      )}
      {shiftTrades && shiftTrades.balance.length > 0 && (
        <Box sx={{ pb: theme.spacing(1) }}>
          <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[500] }}>
            Balance
          </Typography>

          {selectedShiftTrade && departmentInfoState.departmentInfo.settings.shiftTradeForgivingEnabled && (
            <GenericDrawerOrModal
              anchor="bottom"
              drawerOpen={selectedShiftTrade !== null}
              handleOnClose={handleOnClose}
              loading={false}
              showHeader={true}
              disableFooter={true}
              headerTitle={`Forgive shift trade with ${selectedShiftTrade.employeeName}`}
              sxProps={() => ({ '& .MuiDrawer-paper': { height: '100%', borderRadius: 0 } })}
            >
              <Box>
                <ShiftTradeForgive shiftTrade={selectedShiftTrade} onClose={handleOnClose} onConfirmed={setConfirmedDrawer} />
              </Box>
            </GenericDrawerOrModal>
          )}

          {shiftTrades.balance.map((shiftTrade, index) =>
            shiftTrade.owedHours && shiftTrade.owedHours > 0 ? (
              <Box
                onClick={() => {
                  if (shiftTrade.owesMe) {
                    setSelectedShiftTrade(shiftTrade);
                  }
                }}
                key={index}
                sx={{ mt: theme.spacing(1) }}
              >
                <ShiftTradeCard shiftTrade={shiftTrade} />
              </Box>
            ) : null,
          )}
        </Box>
      )}
    </BlankCard>
  );
};
