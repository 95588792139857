import { Box } from '@mui/material';
import { XCloseIcon16 } from '../../assets';

export const DrawerHeader = ({
  headerTitle,
  handleOnClose,
  noBorder,
  headerIcon,
}: {
  headerTitle: string;
  handleOnClose: () => void;
  noBorder?: boolean;
  headerIcon?: React.ReactNode;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({
        height: '64px',
        width: '100%',
        padding: '20px 16px 16px 16px',
        flexShrink: 0,
        borderBottom: noBorder ? 'none' : `1px dashed ${theme.palette.stationGray[100]}`,
        position: 'sticky',
        top: 0,
        background: theme.palette.common.white,
        zIndex: theme.zIndex.appBar,
        mb: theme.spacing(1),
      })}
    >
      <Box sx={{ typography: 'bodyLSemibold' }}>{headerTitle}</Box>
      <Box
        data-cy="close-button"
        onClick={handleOnClose}
        sx={(theme) => ({
          width: '28px',
          height: '28px',
          borderRadius: '20px',
          background: theme.palette.stationGray[100],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.stationGray[500],
          ':hover': {
            cursor: 'pointer',
          },
        })}
      >
        {headerIcon ? headerIcon : <XCloseIcon16 />}
      </Box>
    </Box>
  );
};
