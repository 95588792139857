import { Certification } from '../certification/models';
import { Rank, Team } from '../organization/models';
import { PayCode } from '../payroll';
import { TemporaryNonShiftType } from '../temporaryNonShift/models';
import { BasicEmployee } from './models';

export interface EmployeeOffView {
  id: number;
  title: string;
  requestType: AllowedEmployeeOffTypes;
  timeOffType: string | null;
  shiftDate: string;
  startTime: string;
  endTime: string;
  note?: string;
  approvedBy: BasicEmployee;
  approvedAt: string;
  employeeOff: SwappedEmployee;
  isReplacementNeeded: boolean;
  positions: string[];
  replacementEmployees: SwappedEmployee[];
  activationDate: string;
  deactivationDate: string;
  payCodes: Pick<PayCode, 'id'>[];
}
export interface SwappedEmployee {
  id: number;
  rank: Rank;
  firstName: string;
  lastName: string;
  startTime?: string;
  endTime?: string;
  certifications: Certification[];
  defaults: { regularAssignmentPayCode: Pick<PayCode, 'id'>; overtimeAssignmentPayCode: Pick<PayCode, 'id'> };
  team: Team | null;
}
export const ALLOWED_EMPLOYEE_OFF_TYPES = [
  'TimeOffRequest',
  'ShiftTradeRequest',
  'KellyDay',
  TemporaryNonShiftType.EXTENDED_LEAVE,
] as const;

export type AllowedEmployeeOffTypes = (typeof ALLOWED_EMPLOYEE_OFF_TYPES)[number];
