import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { TimeOffLimit } from '@stationwise/share-types';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { SnackbarService } from '../Snackbar';

interface AccrualEditModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  accruals: TimeOffLimit[];
  handleRefresh?: () => void;
}

export const AccrualEditModal = ({ showModal, setShowModal, accruals, handleRefresh }: AccrualEditModalProps) => {
  const [currentAccruals, setCurrentAccruals] = useState<TimeOffLimit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [difference, setDifference] = useState<boolean>(false);
  const disabled = isLoading || !difference;

  useEffect(() => {
    setCurrentAccruals(accruals.filter((accrual) => !accrual.isUnlimited).map((accrual) => ({ ...accrual })));
  }, [accruals]);
  useEffect(() => {
    let isDifferent = false;

    for (let i = 0; i < currentAccruals.length; i++) {
      const accrual = currentAccruals[i];
      const original = accruals.find((originalAccrual) => originalAccrual.payCode.id === accrual.payCode.id);

      if (!!original && original.accruedTimeOff.toFixed(2) !== accrual.accruedTimeOff.toFixed(2)) {
        isDifferent = true;
        break;
      }
    }

    setDifference(isDifferent);
  }, [accruals, currentAccruals]);

  const closeModal = () => {
    setIsLoading(false);
    setShowModal(false);
  };
  const apply = async () => {
    setIsLoading(true);
    try {
      if (showModal) {
        const params = currentAccruals.map((accrual) => ({
          id: accrual.id,
          accruedTimeOff: accrual.accruedTimeOff,
        }));
        await client.patch('/request/time-off-limits/bulk_update/', params);
        handleRefresh && handleRefresh();
        SnackbarService.notify({
          content: `Accruals updated successfully.`,
          severity: 'success',
          duration: 5000,
        });
        closeModal();
      }
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: `An error occurred while updating accruals; ${isAxiosError(error) ? JSON.stringify(error.response?.data) : ''}`,
        severity: 'error',
        duration: 5000,
      });
      closeModal();
    }
  };

  return (
    <Modal open={showModal} setOpen={setShowModal} hideCloseIcon={false}>
      <Box
        sx={(theme) => ({
          borderRadius: theme.spacing(2),
          width: '428px',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Box
          sx={{
            width: '428px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: theme.spacing(1),
              alignSelf: 'stretch',
            })}
          >
            <Typography variant={'h6'} sx={{ color: 'text.primary' }}>
              Accruals
            </Typography>
            <Typography
              variant={'subtitle1'}
              sx={{
                color: 'text.secondary',
              }}
            >
              Modify Accruals
            </Typography>
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            mt: theme.spacing(4),
            width: '428px',
            alignItems: 'flex-start',
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
          })}
        >
          {currentAccruals.map((accrual, index) => (
            <Box
              key={index}
              sx={(theme) => ({
                width: '428px',
                display: 'flex',
                gap: theme.spacing(3),
              })}
            >
              <TextField
                label={accrual.payCode.name}
                onChange={(e) => {
                  const updatedAccruals = currentAccruals.map((item) =>
                    item.id === accrual.id
                      ? {
                          ...item,
                          accruedTimeOff:
                            Number(e.target.value) < (accrual.payCode.maxAccrualLimit ?? 365 * 24)
                              ? Number(e.target.value) < 0
                                ? 0
                                : Number(e.target.value)
                              : accrual.payCode.maxAccrualLimit ?? 365 * 24,
                        }
                      : item,
                  );
                  setCurrentAccruals(updatedAccruals);
                }}
                type="number"
                variant="outlined"
                size="medium"
                fullWidth={true}
                value={accrual.accruedTimeOff}
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end">h</InputAdornment>,
                    inputMode: 'decimal', // For mobile devices
                  },
                  htmlInput: {
                    input: {
                      inputProps: { min: 0, max: accrual.payCode.maxAccrualLimit ?? Number.MAX_SAFE_INTEGER },
                    },
                  },
                }}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={(theme) => ({
            mt: theme.spacing(3),
            display: 'flex',
            py: theme.spacing(3),
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            width: '100%',
            gap: theme.spacing(1),
          })}
        >
          <Button
            onClick={closeModal}
            buttonType="tertiary"
            sx={(theme) => ({
              color: theme.palette.stationBlue[500],
            })}
          >
            {'Cancel'}
          </Button>
          <Button
            onClick={apply}
            buttonType="primary"
            disabled={disabled}
            sx={(theme) => ({
              backgroundColor: theme.palette.stationBlue[700],
              color: theme.palette.common.white,
            })}
          >
            {'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
