import { Box, Typography } from '@mui/material';
import { captureMessage } from '@sentry/react';
import { useDocumentTitle } from '../../hooks';
import { Button } from '../Button';

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  body?: string;
}

export const ErrorPage = ({ title, subtitle, body }: ErrorPageProps) => {
  useDocumentTitle('Oops! An Error Occurred');
  captureMessage('Error Page Rendered', {
    extra: {
      currentUrl: window.location.href,
    },
  });
  return (
    <Box component="main" sx={{ py: 8, px: 5 }}>
      <Typography variant="h1" component="h1" sx={{ mb: 3 }}>
        {title || 'Oops!'}
      </Typography>
      <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
        {subtitle || "There's an error and we are working on it"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 1.5 }}>
        {body || 'Contact support if the issue persists'}
      </Typography>
      <Button href="/" buttonType="dark">
        Go to Dashboard
      </Button>
    </Box>
  );
};
