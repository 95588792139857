import { Button, Checkbox, FormControlLabel, Menu, MenuItem, useTheme } from '@mui/material';
import { useId, useState } from 'react';
import { ChevronDownIcon16 } from '../../../../assets';
import { cancelShiftTradeSplit, cancelTimeOffSplit, createTimeOffSplit, removeSplit } from './actions';
import { SplitProps } from './types';

interface SplitAction {
  callback: () => void;
  text: string;
}

type SplitActionsProps = SplitProps;

export const SplitActions = (props: SplitActionsProps) => {
  const theme = useTheme();
  const { isPlannedEmployee, split } = props;

  const id = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (split.backup.reference.type === 'ASSIGNMENT' && isPlannedEmployee) {
    return (
      <FormControlLabel
        label="Employee off"
        sx={{ m: theme.spacing('-10px', 0, 0, 0) }}
        control={
          <Checkbox
            checked={split.reference.type === 'TIME_OFF_REQUEST'}
            onChange={(event) => (event.target.checked ? createTimeOffSplit(props) : cancelTimeOffSplit(props))}
          />
        }
      />
    );
  }

  const actions: SplitAction[] = [];
  if (split.reference.type === 'ASSIGNMENT' && !split.reference.trade && !isPlannedEmployee) {
    actions.push({ callback: () => removeSplit(props), text: 'Restore position on staffing list' });
  }
  if (
    (split.reference.type === 'SHIFT_TRADE_REQUEST' && split.reference.id > 0) ||
    (split.reference.type === 'ASSIGNMENT' && split.reference.trade && split.reference.trade.id > 0)
  ) {
    actions.push({ callback: () => cancelShiftTradeSplit(props, 'REFUND_HOURS'), text: 'Cancel trade: Undo traded hours' });
    actions.push({ callback: () => cancelShiftTradeSplit(props, 'KEEP_BALANCE'), text: 'Cancel trade: Leave hours balance' });
  }
  if (split.reference.type === 'ASSIGNMENT') {
    actions.push({ callback: () => createTimeOffSplit(props), text: 'Employee off' });
  }
  if (split.reference.type === 'TIME_OFF_REQUEST') {
    actions.push({ callback: () => cancelTimeOffSplit(props), text: 'Cancel' });
  }

  if (!actions.length) {
    return null;
  }

  return (
    <>
      <Button
        id={`${id}button`}
        aria-controls={anchorEl ? `${id}menu` : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        variant="outlined"
        color="warning"
        onClick={(event) => (actions.length > 1 ? setAnchorEl(event.currentTarget) : actions[0].callback())}
        sx={{
          mt: '-7px',
          p: '9px 16px',
          textTransform: 'none',
          gap: 0.5,
          '& svg': { minWidth: '16px' },
          '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
        }}
      >
        {actions.length > 1 ? 'Actions' : actions[0].text}
        {actions.length > 1 && <ChevronDownIcon16 />}
      </Button>
      {actions.length > 1 && (
        <Menu
          id={`${id}menu`}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: -8, horizontal: 'right' }}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ 'aria-labelledby': `${id}button` }}
          sx={{
            '& .MuiMenu-paper': {
              border: `1px solid ${theme.palette.stationGray[100]}`,
              borderRadius: '12px',
            },
            '& .MuiMenu-list': {
              p: theme.spacing(1.5),
            },
            '& .MuiMenuItem-root': {
              borderRadius: '8px',
              color: theme.palette.stationGray[900],
              p: theme.spacing(1, 1.5),
              typography: 'bodySRegular',
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: theme.palette.stationGray[100],
            },
          }}
        >
          {actions.map(({ callback, text }, actionIndex) => (
            <MenuItem key={actionIndex} onClick={callback}>
              {text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
