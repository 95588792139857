import { TextField, Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { FormEvent, useDeferredValue, useEffect, useState } from 'react';
import {
  Button,
  Filter,
  Input,
  ResponsibilityCandidateCard,
  SearchIcon20,
  SnackbarService,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { ResponsibilityEmployee, Responsibility } from '@stationwise/share-types';
import { DurationSelectionModal } from './DurationSelectionModal';

interface ResponsibilityFormProps {
  setShowResponsibilityOpen: (value: boolean) => void;
  responsibility?: Responsibility;
  currentDate: Date;
  forceFetch: () => void;
}

export const ResponsibilityForm = ({
  setShowResponsibilityOpen,
  responsibility,
  currentDate,
  forceFetch,
}: ResponsibilityFormProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedRanks, setSelectedRanks] = useState<Set<string>>(new Set());
  const deferredSearchValue = useDeferredValue(searchValue);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const [candidates, setCandidates] = useState<ResponsibilityEmployee[]>([]);
  const [showDurationSelectionModal, setShowDurationSelectionModal] = useState(false);
  const [newResponsibility, setNewResponsibility] = useState<Responsibility>(responsibility ? responsibility : {});
  const [showDeleteDurationSelectionModal, setShowDeleteDurationSelectionModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = 'shift/responsibility-candidates/';
        const params = {
          search: searchValue || undefined,
          ranks: Array.from(selectedRanks),
        };

        const response = await client.get(url, {
          params,
        });
        setCandidates(response.data);
      } catch (error) {
        captureException(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredSearchValue, selectedRanks]);

  useEffect(() => {
    if (responsibility === undefined) {
      setNewResponsibility((prevResponsibility) => ({
        ...prevResponsibility,
        employee: undefined,
      }));
    }
  }, [deferredSearchValue, selectedRanks, setNewResponsibility, responsibility]);

  const handleSubmit = async (isOneDay: boolean) => {
    const url = 'shift/responsibility/';

    try {
      if (responsibility) {
        const params = {
          date: format(currentDate, 'yyyy-MM-dd'),
          isOneDay: isOneDay,
          name: newResponsibility.name,
          employee: newResponsibility.employee?.id,
          id: responsibility.id,
        };
        await client.patch(url + responsibility.id + '/', params);
        SnackbarService.notify({
          content: 'Responsibility was updated successfully.',
          severity: 'success',
          showCloseButton: true,
          duration: 5000,
        });
      } else {
        const startDate = format(currentDate, 'yyyy-MM-dd');
        let endDate;
        if (isOneDay) {
          const nextDay = new Date(currentDate);
          nextDay.setDate(currentDate.getDate() + 1);
          endDate = format(nextDay, 'yyyy-MM-dd');
        } else {
          const nextYear = currentDate.getFullYear() + 1;
          endDate = format(new Date(nextYear, 0, 1), 'yyyy-MM-dd');
        }

        const params = {
          name: newResponsibility.name,
          employee: newResponsibility.employee?.id,
          startDate: startDate,
          endDate: endDate,
        };

        await client.post(url, params);

        SnackbarService.notify({
          content: 'Responsibility was created successfully.',
          severity: 'success',
          showCloseButton: true,
          duration: 5000,
        });
      }
    } catch (err) {
      captureException(err);

      const errorMessage =
        axios.isAxiosError(err) && err.response?.data?.nonFieldErrors
          ? err.response.data.nonFieldErrors
          : 'Unexpected error. Try again later.';

      SnackbarService.notify({
        content: errorMessage,
        severity: 'error',
        showCloseButton: true,
        duration: 5000,
      });
    }
    forceFetch();
    setShowResponsibilityOpen(false);
  };

  const handleDelete = async (isOneDay: boolean) => {
    const url = 'shift/responsibility/';
    try {
      if (responsibility) {
        const params = {
          date: format(currentDate, 'yyyy-MM-dd'),
          isOneDay: isOneDay,
          isDelete: true,
        };
        await client.patch(url + responsibility.id + '/', params);

        SnackbarService.notify({
          content: 'Responsibility was deleted successfully.',
          severity: 'success',
          showCloseButton: true,
          duration: 5000,
        });
      } else {
        SnackbarService.notify({
          content: 'There is no responsibility to delete',
          showCloseButton: true,
          duration: 5000,
          severity: 'error',
        });
      }
    } catch (err) {
      captureException(err);
      SnackbarService.notify({
        content: 'Unexpected error. Try again later.',
        severity: 'error',
        showCloseButton: true,
        duration: 5000,
      });
    }
    forceFetch();

    setShowResponsibilityOpen(false);
  };

  const selectResponsibilityCandidate = (responsibilityCandidate: ResponsibilityEmployee) => {
    setNewResponsibility({ ...newResponsibility, employee: responsibilityCandidate });
  };

  const filters = (
    <Filter
      value={selectedRanks}
      onChange={setSelectedRanks}
      filterName="rank"
      filters={departmentContext.departmentInfo.ranks}
    />
  );
  const searchResults = candidates.map((candidate) => (
    <ResponsibilityCandidateCard
      key={candidate.id}
      candidate={candidate}
      isSelected={candidate.id === newResponsibility.employee?.id}
      onClick={selectResponsibilityCandidate}
    />
  ));

  const closeSelectDurationModal = () => {
    setShowDurationSelectionModal(false);
  };

  const closeDeleteDurationModal = () => {
    setShowDeleteDurationSelectionModal(false);
  };

  const onDelete = () => {
    setShowDeleteDurationSelectionModal(true);
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowDurationSelectionModal(true);
  };

  const closeResponsibilityForm = () => {
    setNewResponsibility({});
    setShowResponsibilityOpen(false);
  };

  return (
    <Box>
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          height: '720px',
          maxHeight: `calc(100vh - ${theme.spacing(20)})`,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          gap: '16px',
        })}
      >
        <Box sx={(theme) => ({ mt: theme.spacing(1) })}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            onChange={(event) => setNewResponsibility({ ...newResponsibility, name: event.target.value })}
            defaultValue={newResponsibility.name}
            sx={{
              // Root styles
              '.MuiOutlinedInput-root': {
                '& fieldset': {
                  boxShadow: 'none',
                },
                '&:hover fieldset': {
                  boxShadow: 'none',
                },
                '&.Mui-focused fieldset': {
                  boxShadow: 'none',
                },
              },
              input: {
                padding: '16.5px 14px',
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box sx={(theme) => ({ typography: theme.typography.body1, pb: theme.spacing(1) })}>Select Person</Box>
          <Input
            placeholder="Search"
            startAdornment={
              <Box component={SearchIcon20} sx={(theme) => ({ color: theme.palette.stationGray[400], mr: theme.spacing(1) })} />
            }
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </Box>
        {filters}
        <Box
          sx={(theme) => ({
            flex: 1,
            overflowY: 'auto',
            m: theme.spacing(2, 0, 10, 0),
            pr: 0.5,
          })}
        >
          {searchResults}
        </Box>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px -2px 4px -1px #0A0E160F, 0px -4px 6px -1px #0A0E161A',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            p: theme.spacing(2, 3, 3, 3),
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
          })}
        >
          <Box>
            {responsibility && (
              <Button
                variant="outlined"
                buttonSize="md"
                buttonType="tertiary"
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.stationRed[500],
                  borderColor: theme.palette.stationRed[500],
                  '&:hover': {
                    backgroundColor: theme.palette.stationRed[500],
                    color: theme.palette.common.white,
                  },
                })}
                onClick={onDelete}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box>
            <Button
              buttonSize="md"
              buttonType="tertiary"
              sx={(theme) => ({ mr: theme.spacing(2), border: 'none', color: theme.palette.stationBlue[500] })}
              onClick={closeResponsibilityForm}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              buttonSize="md"
              buttonType="primary"
              sx={() => ({ flex: 1 })}
              onClick={handleFormSubmit}
              disabled={!newResponsibility.name || !newResponsibility.employee}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>

      <DurationSelectionModal
        showConfirmationModal={showDurationSelectionModal}
        setShowConfirmationModal={setShowDurationSelectionModal}
        title={responsibility ? 'Edit Assignment' : 'New Assignment'}
        onCancel={closeSelectDurationModal}
        handleSubmit={handleSubmit}
      />

      <DurationSelectionModal
        showConfirmationModal={showDeleteDurationSelectionModal}
        setShowConfirmationModal={setShowDeleteDurationSelectionModal}
        title={'Delete Assignment'}
        onCancel={closeDeleteDurationModal}
        handleSubmit={handleDelete}
      />
    </Box>
  );
};
