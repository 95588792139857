import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, isValid, startOfDay } from 'date-fns';
import { useDeferredValue, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ErrorPage,
  Modal,
  SnackbarService,
  useFetchStaffingLists,
  useSelectedStaffingList,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { WarningModal } from '../../../../components/Common';
import { Exemptions } from './Exemption/Exemptions';
import { SelectedStaffingListControls } from './SelectedStaffingListControls';
import { StaffingListControls } from './StaffingListControls';
import { StaffingListDialog } from './StaffingListDialog';
import { StaffingListItems } from './StaffingListItems';

type ActionType = 'Create' | 'Edit';

export const StaffingListsContent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showStaffingListActions = queryParams.get('showStaffingListActions');

  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedStation, setSelectedStation] = useState('');
  const [selectedLastOvertimeDate, setSelectedLastOvertimeDate] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(startOfDay(new Date()));
  const [staffingListDialogOpen, setStaffingListDialogOpen] = useState<boolean>(false);
  const [staffingListWarningOpen, setStaffingListWarningOpen] = useState<boolean>(false);
  const [actionInProgress, setActionInProgress] = useState<ActionType | null>(null);
  const staffingListsResponse = useFetchStaffingLists();

  const { isLoading, isError, staffingLists, selectedStaffingList, setSelectedStaffingList, forceRefetch } =
    useSelectedStaffingList({
      selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
      staffingListsResponse,
    });

  const onAction = (actionType: ActionType) => {
    setActionInProgress(actionType);
    setStaffingListDialogOpen(true);
  };

  const deleteStaffingList = async () => {
    setStaffingListWarningOpen(false);
    try {
      await client.delete(`/staffing-list/staffing-lists/${selectedStaffingList?.id}/`);

      staffingListsResponse.forceRefetch();
      SnackbarService.notify({
        content: `The ${selectedStaffingList?.name} list was deleted successfully.`,
        severity: 'success',
        duration: 3000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: `An error occurred, the staffing list could not be deleted.`,
        severity: 'error',
        duration: 5000,
      });
    }
  };

  return (
    <Box sx={{ minWidth: '650px', width: '80%', px: 5, pb: 8, mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" gap={2}>
          <StaffingListControls
            isLoading={isLoading}
            staffingLists={staffingLists}
            selectedStaffingList={selectedStaffingList}
            setSelectedStaffingList={setSelectedStaffingList}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <Exemptions
            staffingLists={staffingLists}
            selectedStaffingList={selectedStaffingList}
            selectedDate={selectedDate || new Date()}
            forceRefetchStaffingList={forceRefetch}
          />
        </Box>
        {showStaffingListActions && (
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button variant="contained" onClick={() => onAction('Create')}>
              + Create
            </Button>
            {selectedStaffingList && (
              <>
                <Button buttonType="tertiary" variant="contained" onClick={() => onAction('Edit')}>
                  Edit
                </Button>
                <Button
                  sx={(theme) => ({
                    background: theme.palette.stationRed[400],
                    border: 'none',
                    color: theme.palette.common.white,
                    '&:hover': { backgroundColor: theme.palette.stationRed[400] },
                  })}
                  buttonType="tertiary"
                  variant="contained"
                  onClick={() => setStaffingListWarningOpen(true)}
                >
                  Delete
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
      <SelectedStaffingListControls
        selectedStaffingList={selectedStaffingList}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selectedLastOvertimeDate={selectedLastOvertimeDate}
        setSelectedLastOvertimeDate={setSelectedLastOvertimeDate}
        setSelectedStation={setSelectedStation}
        selectedStation={selectedStation}
      />
      {!isLoading && isError && <ErrorPage />}
      {!isError && (
        <StaffingListItems
          isLoading={isLoading}
          selectedStaffingList={selectedStaffingList}
          searchInput={deferredSearchInput}
          selectedTeam={selectedTeam}
          selectedLastOvertimeDate={selectedLastOvertimeDate}
          selectedStation={selectedStation}
        />
      )}

      <StaffingListDialog
        staffingListDialogOpen={staffingListDialogOpen}
        setStaffingListDialogOpen={setStaffingListDialogOpen}
        selectedStaffingList={actionInProgress === 'Edit' ? selectedStaffingList : undefined}
        staffingLists={staffingLists}
        forceRefetch={staffingListsResponse.forceRefetch}
        setSelectedStaffingList={setSelectedStaffingList}
      />
      <Modal open={staffingListWarningOpen} setOpen={setStaffingListWarningOpen}>
        <WarningModal setModalOpen={setStaffingListWarningOpen} onConfirm={deleteStaffingList}>
          <Box sx={(theme) => ({ width: '416px', mb: theme.spacing(3) })}>
            <Box sx={{ typography: 'bodyXXLSemibold' }}>{`You are about to delete ${selectedStaffingList?.name} list`}</Box>
            <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>Are you sure?</Box>
          </Box>
        </WarningModal>
      </Modal>
    </Box>
  );
};
