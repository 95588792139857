import { Box, Checkbox, Dialog, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { Button, LetterAvatar, SearchInput, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { BasicEmployee } from '@stationwise/share-types';

interface DownloadTimeoffsModalProps {
  open: boolean;
  onClose: () => void;
  selectedEmployees: BasicEmployee[];
  setSelectedEmployees: React.Dispatch<React.SetStateAction<BasicEmployee[]>>;
  handleExport: () => void;
}

export const DownloadTimeoffsModal = ({
  open,
  onClose,
  selectedEmployees,
  setSelectedEmployees,
  handleExport,
}: DownloadTimeoffsModalProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [employees, setEmployees] = useState<BasicEmployee[]>([]);

  const fetchEmployees = async (search: string) => {
    try {
      const params = new URLSearchParams();
      if (search) params.append('search', search);

      const response = await client.get('/employee/employees/', { params });
      setEmployees(response.data);
    } catch (error) {
      captureException(error);
    }
  };

  const handleEmployeeSelect = (employee: BasicEmployee) => {
    setSelectedEmployees((prev) => {
      const isAlreadySelected = prev.some((e) => e.id === employee.id);
      if (isAlreadySelected) {
        return prev.filter((e) => e.id !== employee.id);
      }
      return [...prev, employee];
    });
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedEmployees(employees);
    } else {
      setSelectedEmployees([]);
    }
  }, [selectAll, setSelectedEmployees, employees]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
  };

  useEffect(() => {
    fetchEmployees(searchTerm);
  }, [searchTerm]);
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={(theme) => ({ display: 'flex', flexDirection: 'column', p: theme.spacing(3) })}>
        <Typography variant="h6">{'Download time-off CSV'}</Typography>
        <Typography variant="subtitle1" color={theme.palette.text.secondary}>
          {'Select people to download the CSV for'}
        </Typography>
      </Box>

      <Box sx={{ width: '300px', ml: theme.spacing(3), mb: theme.spacing(2) }}>
        <SearchInput value={searchTerm} setValue={setSearchTerm} placeHolder="Search by name or email" />
      </Box>

      <Box sx={{ height: '400px', overflow: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', px: theme.spacing(2) }}>
          <Checkbox checked={selectAll} onChange={handleSelectAll} onClick={(e) => e.stopPropagation()} />
          <Typography sx={{ typography: 'body1', color: theme.palette.text.primary }}>Select all</Typography>
        </Box>

        {selectedEmployees.length > 0 && (
          <>
            <Box sx={{ typography: 'subtitle2', color: theme.palette.text.primary, p: theme.spacing(3) }}>Selected Employees</Box>
            {selectedEmployees.map((employee) => (
              <Box
                key={employee.id}
                onClick={() => handleEmployeeSelect(employee)}
                sx={{
                  py: 2,
                  px: 2,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <Checkbox
                  checked={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEmployeeSelect(employee);
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <LetterAvatar firstName={employee.name} />
                  <Box>
                    <Box sx={{ typography: 'subtitle1' }}>{employee.name}</Box>
                    <Box sx={{ typography: 'body2', color: 'text.secondary' }}>{employee.rank.name}</Box>
                  </Box>
                </Box>
              </Box>
            ))}
            <Box sx={{ borderBottom: '2px solid', borderColor: 'divider', my: 2 }} />
          </>
        )}

        {employees
          .filter((employee) => !selectedEmployees.some((e) => e.id === employee.id))
          .map((employee) => (
            <Box
              key={employee.id}
              onClick={() => handleEmployeeSelect(employee)}
              sx={{
                py: 2,
                px: 2,
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              <Checkbox
                checked={false}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEmployeeSelect(employee);
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <LetterAvatar firstName={employee.name} />
                <Box>
                  <Box sx={{ typography: 'subtitle1' }}>{employee.name}</Box>
                  <Box sx={{ typography: 'body2', color: 'text.secondary' }}>{employee.rank.name}</Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>

      <Box
        sx={(theme) => ({
          justifyContent: 'space-between',
          display: 'flex',
          mt: theme.spacing(5),
          width: '100%',
          gap: theme.spacing(1),
        })}
      >
        <Button
          buttonType="tertiary"
          variant="contained"
          style={{
            width: '216px',
            height: '44px',
            padding: '9px 17px 9px 17px',
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(3),
          }}
          onClick={() => {
            onClose();
            setSelectedEmployees([]);
            setSearchTerm('');
          }}
        >
          <Box component="span" sx={{ typography: 'buttonM' }}>
            Cancel
          </Box>
        </Button>
        <Button
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            borderRadius: theme.spacing(0.75),
            '&:hover': { backgroundColor: theme.palette.primary.dark, boxShadow: 'none' },
            width: '216px',
            height: '44px',
            padding: '9px 17px 9px 17px',
            mr: theme.spacing(3),
            mb: theme.spacing(3),
          })}
          buttonType="tertiary"
          variant="contained"
          disableFocusRipple={true}
          disableTouchRipple={true}
          disabled={selectedEmployees.length === 0}
          onClick={handleExport}
        >
          <Box component="span" sx={{ typography: 'buttonM' }}>
            Download CSV
          </Box>
        </Button>
      </Box>
    </Dialog>
  );
};
