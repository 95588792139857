import { Autocomplete, Box, Typography, TextField, InputAdornment } from '@mui/material';
import { captureException } from '@sentry/react';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  Button,
  DatePickerComp,
  Modal,
  SnackbarService,
  useLoadedDepartmentInfoContext,
  RankBadge,
  CertBadge,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { Certification, Employee, Rank } from '@stationwise/share-types';

interface PromoteModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRanks: Rank[];
  setSelectedRanks: React.Dispatch<React.SetStateAction<Rank[]>>;
  selectedEmployeeIds: string[];
  setSelectedEmployeeIds: React.Dispatch<React.SetStateAction<string[]>>;
  employees: Employee[];
  setFetchCounter: React.Dispatch<React.SetStateAction<number>>;
}

export const PromoteModal = ({
  showModal,
  setShowModal,
  selectedRanks,
  setSelectedRanks,
  selectedEmployeeIds,
  setSelectedEmployeeIds,
  employees,
  setFetchCounter,
}: PromoteModalProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const [promotedRank, setPromotedRank] = useState<Rank | null>(null);
  const [certificationsLostIds, setCertificationsLostIds] = useState<number[]>([]);
  const [certificationsGainedIds, setCertificationsGainedIds] = useState<number[]>([]);
  const [effectiveStartDate, setEffectiveStartDate] = useState<Date | null>(null);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [availableCertifications, setAvailableCertifications] = useState<Certification[]>([]);
  const ranks = departmentInfoState.departmentInfo.ranks;
  const certifications = departmentInfoState.departmentInfo.certifications;

  useEffect(() => {
    const selectedCertificationsSet = new Set<string>();
    const uniqueCertifications: Certification[] = [];

    selectedEmployeeIds.forEach((id) => {
      const employee = employees.find((emp) => emp.id === id);
      if (employee) {
        employee.certifications?.forEach((cert) => {
          if (!selectedCertificationsSet.has(cert.code)) {
            selectedCertificationsSet.add(cert.code);
            uniqueCertifications.push(cert);
          }
        });
      }
    });

    setAvailableCertifications(uniqueCertifications);
  }, [selectedEmployeeIds, employees]);

  const closeModal = () => {
    setShowModal(false);
  };
  const apply = async () => {
    try {
      if (effectiveStartDate) {
        const params = {
          employeeIds: selectedEmployeeIds,
          targetRankId: promotedRank?.id,
          certificationsLostIds: certificationsLostIds,
          certificationsGainedIds: certificationsGainedIds,
          effectiveStartDate: format(effectiveStartDate, 'yyyy-MM-dd'),
          expirationDate: expirationDate ? format(expirationDate, 'yyyy-MM-dd') : null,
        };
        await client.post('/employee/promote/', params);
        SnackbarService.notify({
          content: `${selectedEmployeeIds.length} users have been promoted.`,
          severity: 'success',
          duration: 5000,
        });
        setSelectedRanks([]);
        setSelectedEmployeeIds([]);
        setShowModal(false);
        setFetchCounter((prev) => prev + 1);
      }
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'An error occurred while promoting users.',
        severity: 'error',
        duration: 5000,
      });
      setShowModal(false);
    }
  };
  const isApplyDisabled = !promotedRank || !effectiveStartDate;

  return (
    <Modal open={showModal} setOpen={setShowModal} hideCloseIcon>
      <Box
        sx={(theme) => ({
          borderRadius: '16px',
          width: '428px',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <Box>
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Rank promotion</Typography>
          <Typography
            sx={(theme) => ({
              typography: 'bodyMRegular',
              mt: theme.spacing(1),
            })}
          >
            {selectedEmployeeIds.length} users selected
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Current rank</Typography>
          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.stationGray[200]}`,
              borderRadius: '8px',
              paddingX: theme.spacing(1.5),
              paddingY: theme.spacing(1.25),
              display: 'flex',
              flexWrap: 'wrap',
              gap: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
            })}
          >
            {selectedRanks.map((rank) => (
              <RankBadge key={rank.id} rank={rank} rankField="name" />
            ))}
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Target rank</Typography>
          <Autocomplete
            options={ranks}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(_event, value) => setPromotedRank(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={promotedRank ? '' : 'Choose rank'}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: promotedRank ? (
                    <InputAdornment position="start">
                      <RankBadge rank={promotedRank} sx={{ mr: 0.5 }} />
                    </InputAdornment>
                  ) : null,
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography sx={{ mr: 1 }}>{option.name}</Typography>
                <RankBadge rank={option} />
              </li>
            )}
            disableClearable
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Certifications lost during promotion</Typography>
          <Autocomplete
            options={availableCertifications}
            getOptionLabel={(option) => `${option.code}`}
            multiple
            onChange={(_event, value) => setCertificationsLostIds(value.map((cert) => cert.id))}
            disabled={availableCertifications.length === 0}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  availableCertifications.length === 0
                    ? 'There is no certification'
                    : certificationsLostIds.length === 0
                      ? 'None'
                      : ''
                }
              />
            )}
            renderTags={(tagValue) => tagValue.map((option) => <CertBadge key={option.code} cert={option} />)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography sx={{ mr: 1 }}>{option.name}</Typography>
                <CertBadge cert={option} />
              </li>
            )}
            disableClearable
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Certifications gained during promotion</Typography>

          <Autocomplete
            options={certifications}
            getOptionLabel={(option) => `${option.code}`}
            multiple
            onChange={(_event, value) => setCertificationsGainedIds(value.map((cert) => cert.id))}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder={certificationsGainedIds.length === 0 ? 'None' : ''} />
            )}
            renderTags={(tagValue) => tagValue.map((option) => <CertBadge key={option.code} cert={option} />)}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography sx={{ mr: 1 }}>{option.name}</Typography>
                <CertBadge cert={option} />
              </li>
            )}
            disableClearable
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Effective start date</Typography>
          <DatePickerComp
            value={effectiveStartDate}
            setValue={setEffectiveStartDate}
            openIconPosition="start"
            placeholder="Choose date"
            format="MMM dd, yyyy"
            minDate={new Date()}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
          })}
        >
          <Typography sx={{ typography: 'bodySMedium' }}>Expiration date (optional)</Typography>
          <DatePickerComp
            value={expirationDate}
            setValue={setExpirationDate}
            openIconPosition="start"
            placeholder="Choose date"
            format="MMM dd, yyyy"
            minDate={effectiveStartDate ? addDays(effectiveStartDate, 1) : addDays(new Date(), 1)}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: theme.spacing(2),
          })}
        >
          <Button onClick={closeModal} buttonType="tertiary" sx={() => ({ width: '100%' })}>
            {'Cancel'}
          </Button>
          <Button
            onClick={apply}
            buttonType="primary"
            sx={(theme) => ({ width: '100%', backgroundColor: theme.palette.stationGray[900] })}
            disabled={isApplyDisabled}
          >
            {'Apply'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
