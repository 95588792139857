import { ReactComponent as MoreHorizSymbol } from '@material-symbols/svg-400/outlined/more_horiz.svg';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface MoreButtonProps {
  disabled?: boolean;
  setRequestMultipleDaysOff?: (value: boolean) => void;
}

export const MoreButton = ({ disabled = false, setRequestMultipleDaysOff }: MoreButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Button
      disabled={disabled}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0.75),
        borderRadius: theme.spacing(0.75),
        typography: 'buttonM',
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: '20px',
      })}
      variant="outlined"
      onClick={handleClick}
    >
      <SvgIcon
        component={MoreHorizSymbol}
        sx={{
          height: '20px',
          width: '20px',
        }}
      />
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(event) => {
            setRequestMultipleDaysOff && setRequestMultipleDaysOff(true);
            handleClose(event);
          }}
        >
          Request multiple days off
        </MenuItem>
      </Menu>
    </Button>
  );
};
