import { ReactComponent as SettingsSymbol } from '@material-symbols/svg-400/outlined/settings-fill.svg';
import { Box, Typography, Checkbox, Chip, Button, ChipProps, Tooltip } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import {
  EmptyCard,
  Loader,
  LottieLogo,
  StaffingListMainControls,
  theme,
  useLoadedDepartmentInfoContext,
  useRosterContext,
  useStaffingListFilterGroup,
  SvgIcon,
} from '@stationwise/component-module';
import { AutoHireInfoView, AutoHireView, EligibleEmployeeForHiringEngine, StaffingListItem } from '@stationwise/share-types';
import { formatShiftDuration, getMissingCertifications } from '@stationwise/shift-summary-helper';
import { HiringEngineStaffinListFilterPopover } from '../HiringEngineStaffingListFilterPopover';
import { RankOrderModal } from '../Vacancies/RankOrderModal';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { AutoHireDaySettings } from './AutoHireDaySettings';
import { AUTO_HIRE_STATUS } from './constant';
import { generateRankOrder } from './utils';

type AutoHireItem = { type: 'autoHire'; data: AutoHireView; positions: HiringEngineVacancy[] };

type MergedItem = { type: 'vacancy'; positions: HiringEngineVacancy[] } | AutoHireItem;

interface AutoHireVacanciesProps {
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  rankOrders: Record<number, { rankId: number; isSelected: boolean }[]>;
  setRankOrders: React.Dispatch<React.SetStateAction<Record<number, { rankId: number; isSelected: boolean }[]>>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  autoHireInfo: AutoHireInfoView;
  handleAutoHireHistoryOpen: (rankId: number) => void;
  vacancyRankCallOrder: number[];
  setVacancyRankCallOrder: React.Dispatch<React.SetStateAction<number[]>>;
  isDateInPast: boolean;
  loading: boolean;
  setCurrentEligibleEmployees: (eligibleEmployees: Record<number, EligibleEmployeeForHiringEngine[]>) => void;
  checkedRanks: Record<number, boolean>;
  setCheckedRanks: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  setSelectedStaffingListId: React.Dispatch<React.SetStateAction<number | null>>;
  roundDuration: number;
  setRoundDuration: React.Dispatch<React.SetStateAction<number>>;
  onRestartAutoHire: (autoHireId: string, eligibleEmployeeIds: string[]) => void;
}

function getAutoHireChipProps(items: AutoHireItem): Pick<ChipProps, 'label' | 'color'> & { tooltip?: string } {
  let label: string = '';
  let color: 'success' | 'warning' | 'info' | 'primary' | 'default' = 'default';
  let tooltip: string | undefined;
  const autoHireStatus = items.data.status;

  if (autoHireStatus === AUTO_HIRE_STATUS.COMPLETED) {
    label = 'Completed';
    color = 'success';
    tooltip = 'All vacancies have been filled';
  } else if (autoHireStatus === AUTO_HIRE_STATUS.ALL_NOTIFIED) {
    label = 'All Notified';
    color = 'success';
    tooltip = 'All eligible employees have been notified, but not all vacancies have been filled';
  } else if (autoHireStatus === AUTO_HIRE_STATUS.IN_PROGRESS) {
    label = 'In Progress';
    color = 'primary';
  } else if (autoHireStatus === AUTO_HIRE_STATUS.IN_QUEUE) {
    label = 'In Queue';
    color = 'info';
    tooltip = 'Waiting for the previous auto hire to complete';
  } else if (autoHireStatus === AUTO_HIRE_STATUS.INACTIVE) {
    label = 'Inactive';
    color = 'default';
  }
  return { label, color, ...(tooltip && { tooltip }) };
}

export const AutoHireVacancies: React.FC<AutoHireVacanciesProps> = ({
  groupedVacancies,
  rankOrders,
  setRankOrders,
  setDisabled,
  autoHireInfo,
  handleAutoHireHistoryOpen,
  vacancyRankCallOrder,
  setVacancyRankCallOrder,
  isDateInPast,
  loading,
  setCurrentEligibleEmployees,
  checkedRanks,
  setCheckedRanks,
  setSelectedStaffingListId,
  roundDuration,
  setRoundDuration,
  onRestartAutoHire,
}) => {
  const [selectedRankId, setSelectedRankId] = useState<number | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVacancyFillModalOpen, setVacancyFillModalOpen] = useState(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { staffingListsState } = useRosterContext();
  const operators = staffingListsState.availableStaffingListItems;

  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [selectedBattalionOptions, setSelectedBattalionOptions] = useState(new Set<string>());
  const [noEligibleEmployees, setNoEligibleEmployees] = useState(false);
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const filterStates = [
    useStaffingListFilterGroup(selectedTeamOptions, setSelectedTeamOptions),
    useStaffingListFilterGroup(selectedBattalionOptions, setSelectedBattalionOptions),
  ];
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const uniqueRanks = useMemo(() => departmentInfo.ranks || [], [departmentInfo.ranks]);

  useEffect(() => {
    const anyChecked = Object.values(checkedRanks).some((checked) => checked);
    setDisabled(!anyChecked);
  }, [checkedRanks, setDisabled]);

  const handleCheckboxChange = (rankId: number) => {
    setCheckedRanks((prev) => {
      const newCheckedRanks = { ...prev, [rankId]: !prev[rankId] };

      if (newCheckedRanks[rankId]) {
        setRankOrders((prev) => {
          const autoHireOrder = autoHireInfo.autoHireDefaultRankOrders[rankId];
          return {
            ...prev,
            [rankId]: autoHireOrder ? autoHireOrder : generateRankOrder(rankId, uniqueRanks),
          };
        });
      }
      if (!Object.values(newCheckedRanks).every((checked) => checked)) {
        setAllChecked(false);
      }

      return newCheckedRanks;
    });
  };

  const handleAllCheckboxChange = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    const newCheckedRanks = Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
      const rankId = Number(rankIdStr);
      const hasActiveAutoHire = autoHireInfo.autoHires.some((autoHire) =>
        autoHire.positions.some((position) => position.rank.id === rankId && autoHire.status != AUTO_HIRE_STATUS.INACTIVE),
      );
      acc[rankId] = newCheckedState && !hasActiveAutoHire && eligibleEmployees[rankId].length > 0;
      return acc;
    }, {});

    setCheckedRanks(newCheckedRanks);
  };

  const handleEditButtonClick = (rankId: number) => {
    setSelectedRankId(rankId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedRankId(null);
  };

  const handleRankOrderSubmit = (newOrders: { rankId: number; isSelected: boolean }[]) => {
    if (selectedRankId) {
      setRankOrders((prev) => ({ ...prev, [selectedRankId]: newOrders }));
    }
    setModalOpen(false);
  };
  const mergedVacancies = useMemo(() => {
    const merged: Record<string, MergedItem> = {};
    autoHireInfo.autoHires.forEach((autoHire) => {
      merged[autoHire.rank.id] = { type: 'autoHire', data: autoHire, positions: autoHire.positions };
    });

    Object.entries(groupedVacancies).forEach(([rankIdStr, vacancies]) => {
      const rankId = Number(rankIdStr);
      if (merged[rankId]?.type === 'autoHire') {
        return;
      }
      merged[rankId] = { type: 'vacancy', positions: vacancies };
    });

    return merged;
  }, [groupedVacancies, autoHireInfo.autoHires]);

  const handleVacancyFillModalClose = () => {
    setVacancyFillModalOpen(false);
  };

  const handleVacancyFillOrderSubmit = (newVacancyFillOrder: number[]) => {
    setVacancyRankCallOrder(newVacancyFillOrder);
    setVacancyFillModalOpen(false);
  };

  const handleEditFillOrder = () => {
    setVacancyFillModalOpen(true);
  };

  const handleRoundDurationChange = (duration: number) => {
    setRoundDuration(duration);
  };

  const sortedRankIds = Object.keys(mergedVacancies)
    .map(Number)
    .sort((a, b) => {
      const isActiveA =
        mergedVacancies[a].type === 'autoHire' && (mergedVacancies[a] as AutoHireItem).data.status != AUTO_HIRE_STATUS.INACTIVE;
      const isActiveB =
        mergedVacancies[b].type === 'autoHire' && (mergedVacancies[b] as AutoHireItem).data.status != AUTO_HIRE_STATUS.INACTIVE;

      if (isActiveA && !isActiveB) return -1;
      if (!isActiveA && isActiveB) return 1;

      return (vacancyRankCallOrder?.indexOf(a) ?? Infinity) - (vacancyRankCallOrder?.indexOf(b) ?? Infinity);
    });

  const activeAutoHireRanks = useMemo(() => {
    const activeRanks = autoHireInfo.autoHires
      .filter((autoHire) => autoHire.status != AUTO_HIRE_STATUS.INACTIVE)
      .map((autoHire) => {
        const rank = uniqueRanks.find((rank) => rank.code === autoHire.rank.code);
        if (!rank) {
          throw new Error(`Could not find rank with code ${autoHire.rank.code}`);
        }
        return rank;
      });

    // Sort the active ranks based on sortedRankIds
    return activeRanks.sort((a, b) => {
      return sortedRankIds.indexOf(a.id) - sortedRankIds.indexOf(b.id);
    });
  }, [autoHireInfo.autoHires, uniqueRanks, sortedRankIds]);

  const eligibleEmployees = useMemo(() => {
    const matchEmployeesToVacancies = (
      staffingListItems: StaffingListItem[],
    ): Record<number, EligibleEmployeeForHiringEngine[]> => {
      const filteredStaffingListItems = staffingListItems.filter((item) => {
        if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) {
          return false;
        }
        if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) {
          return false;
        }
        return true;
      });

      const rankGroupEligibleEmployeeMapping: Record<number, EligibleEmployeeForHiringEngine[]> = Object.keys(
        groupedVacancies,
      ).reduce(
        (acc, rankId) => {
          acc[Number(rankId)] = [];
          return acc;
        },
        {} as Record<number, EligibleEmployeeForHiringEngine[]>,
      );

      filteredStaffingListItems.forEach((staffingListItem) => {
        Object.entries(groupedVacancies).forEach(([rankId, vacancies]) => {
          const targetRankId = Number(rankId);
          // Check if either there's no rank order yet, or if the employee's rank is selected in the rank order
          const isRankEligible =
            !rankOrders[targetRankId] ||
            rankOrders[targetRankId].some(
              (rankOrder) => rankOrder.rankId === staffingListItem.employee.rank.id && rankOrder.isSelected,
            );

          if (isRankEligible) {
            // Check certifications
            if (
              vacancies.some(
                (vacancy) =>
                  getMissingCertifications(vacancy.certifications, staffingListItem.employee.certifications).length === 0,
              )
            ) {
              rankGroupEligibleEmployeeMapping[targetRankId].push({
                id: staffingListItem.employee.id,
                name: staffingListItem.employee.name,
                rank: staffingListItem.employee.rank,
                certifications: staffingListItem.employee.certifications,
                plannedShiftDetails: [],
              });
            }
          }
        });
      });

      // Sort each rank group based on rank orders
      Object.keys(rankGroupEligibleEmployeeMapping).forEach((rankId) => {
        const targetRankId = Number(rankId);
        if (rankOrders[targetRankId]) {
          rankGroupEligibleEmployeeMapping[targetRankId].sort((a, b) => {
            const rankOrderA = rankOrders[targetRankId].findIndex((order) => order.rankId === a.rank.id && order.isSelected);
            const rankOrderB = rankOrders[targetRankId].findIndex((order) => order.rankId === b.rank.id && order.isSelected);

            if (rankOrderA === rankOrderB) {
              // If same rank priority, maintain original staffing list order
              return (
                filteredStaffingListItems.findIndex((item) => item.employee.id === a.id) -
                filteredStaffingListItems.findIndex((item) => item.employee.id === b.id)
              );
            }

            return rankOrderA - rankOrderB;
          });
        }
      });

      return rankGroupEligibleEmployeeMapping;
    };
    return matchEmployeesToVacancies(operators);
  }, [operators, groupedVacancies, selectedTeamOptions, selectedBattalionOptions, rankOrders]);

  useEffect(() => {
    setCurrentEligibleEmployees(eligibleEmployees);
  }, [eligibleEmployees, setCurrentEligibleEmployees]);

  useEffect(() => {
    setRoundDuration(autoHireInfo.autoHireNotificationInterval);
  }, [autoHireInfo.autoHireNotificationInterval, setRoundDuration]);

  useEffect(() => {
    if (staffingListsState.selectedStaffingList?.id) {
      setSelectedStaffingListId(staffingListsState.selectedStaffingList.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingListsState.selectedStaffingList]);

  useEffect(() => {
    let noEligibleEmployeesFlag = true;
    Object.keys(groupedVacancies).forEach((rankId) => {
      if (eligibleEmployees[Number(rankId)].length === 0) {
        checkedRanks[Number(rankId)] = false;
      } else {
        noEligibleEmployeesFlag = false;
      }
      setNoEligibleEmployees(noEligibleEmployeesFlag);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleEmployees, checkedRanks, allChecked]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 1.5,
          height: '750px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {' '}
        <LottieLogo />{' '}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1.5, height: '750px' }}>
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flex: '1',
              maxWidth: 'calc(100% - 48px)',
            }}
          >
            <StaffingListMainControls
              setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
              filterStates={filterStates}
              isHiringEngine={true}
            />
          </Box>
          <Button
            onClick={handleEditFillOrder}
            disabled={loading}
            disableRipple
            startIcon={<SvgIcon component={SettingsSymbol} sx={{ color: theme.palette.action.active }} />}
            sx={() => ({
              minWidth: '32px',
              minHeight: '75px',
              flexShrink: 0,
              '&:hover': {
                background: 'transparent',
              },
            })}
          />
        </Box>
        <HiringEngineStaffinListFilterPopover
          items={operators}
          filterPopoverAnchorEl={filterPopoverAnchorEl}
          setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
          filterStates={filterStates}
        />
      </>
      {Object.keys(sortedRankIds).length !== Object.keys(autoHireInfo.autoHires).length && !isDateInPast && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            px: theme.spacing(2),
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={allChecked} onChange={handleAllCheckboxChange} disabled={noEligibleEmployees} />
            <Typography sx={{ typography: 'bodySMedium', opacity: noEligibleEmployees ? 0.5 : 1 }}>Select All</Typography>
          </Box>
        </Box>
      )}
      {staffingListsState.isLoading && <Loader />}
      {!staffingListsState.isLoading && !staffingListsState.isError && (
        <Box
          sx={() => ({
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          {sortedRankIds.map((rankId) => {
            const item = mergedVacancies[rankId] || null;
            if (!item) return null;

            const rankName = uniqueRanks
              .find((rank) => rank.id === rankId)
              ?.name.toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase());

            const restartDisabled = !eligibleEmployees[rankId]?.length;

            return (
              <Box
                key={rankId}
                sx={{
                  p: theme.spacing(0, 2, 2, 2),
                  mt: 1,
                  width: '100%',
                  backgroundColor: theme.palette.stationGray[50],
                  marginTop: theme.spacing(2),
                  borderRadius: '16px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(1.5) }}>
                  {item.type != 'autoHire' && eligibleEmployees[rankId].length === 0 && (
                    <Chip
                      label="No eligible employees"
                      color="error"
                      size="small"
                      variant="filled"
                      sx={{ mb: theme.spacing(0.5), width: '50%' }}
                    />
                  )}
                </Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[500],
                    display: 'flex',
                    gap: theme.spacing(4),
                    typography: 'bodySMedium',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  })}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {item.type != 'autoHire' && (
                      <Checkbox
                        checked={checkedRanks[rankId] || false}
                        onChange={() => handleCheckboxChange(rankId)}
                        disabled={eligibleEmployees[rankId].length <= 0}
                      />
                    )}
                    <Typography>{rankName}</Typography>
                  </Box>
                  {item.type === 'vacancy' && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => handleEditButtonClick(rankId)}
                      disabled={loading}
                    >
                      Edit Rank Order
                    </Button>
                  )}
                  {item.type === 'autoHire' && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                      {(() => {
                        const chipProps = getAutoHireChipProps(item);
                        return chipProps.tooltip ? (
                          <Tooltip title={chipProps.tooltip}>
                            <Chip {...chipProps} size="small" variant="filled" />
                          </Tooltip>
                        ) : (
                          <Chip {...chipProps} size="small" variant="filled" />
                        );
                      })()}
                      <Button variant="text" color="success" size="small" onClick={() => handleAutoHireHistoryOpen(rankId)}>
                        View
                      </Button>
                    </Box>
                  )}
                </Box>
                {item.positions.map((position, index) => (
                  <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', maxWidth: '255px' }}>
                      <Typography
                        variant="caption"
                        sx={(theme) => ({
                          color: theme.palette.stationGray[400],
                          display: 'block',
                          mb: theme.spacing(0.5),
                          mt: theme.spacing(1),
                          textAlign: 'center',
                        })}
                      >
                        {`${position.stationName?.toUpperCase()} | ${position.apparatusName?.toUpperCase()}`}
                      </Typography>
                      <EmptyCard
                        rank={position.rank}
                        durationLabel={formatShiftDuration({
                          startTime: position.startDateTime,
                          endTime: position.endDateTime,
                        })}
                        certs={[]}
                        cardSxProps={{
                          height: '48px',
                          [theme.breakpoints.up('sm')]: {
                            height: '40px',
                          },
                          overflow: 'hidden',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
                {item.type === 'autoHire' &&
                  [AUTO_HIRE_STATUS.INACTIVE, AUTO_HIRE_STATUS.ALL_NOTIFIED].includes(item.data.status) && (
                    <Tooltip title={restartDisabled ? 'No eligible employees' : ''}>
                      <Box sx={{ mt: 2, width: '100%' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          fullWidth
                          sx={() => ({
                            textTransform: 'capitalize',
                            borderWidth: '2px',
                            '&.Mui-disabled': {
                              borderWidth: '2px',
                            },
                          })}
                          onClick={() => {
                            onRestartAutoHire(
                              item.data.id.toString(),
                              eligibleEmployees[item.data.rank.id].map((employee) => employee.id),
                            );
                          }}
                          disabled={restartDisabled}
                        >
                          Restart
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
              </Box>
            );
          })}
        </Box>
      )}
      {selectedRankId && (
        <RankOrderModal
          open={isModalOpen}
          setOpen={handleModalClose}
          selectedRankId={selectedRankId}
          rankOrders={rankOrders[selectedRankId] || []}
          onSubmit={handleRankOrderSubmit}
          uniqueRanks={uniqueRanks}
        />
      )}
      <AutoHireDaySettings
        open={isVacancyFillModalOpen}
        setOpen={handleVacancyFillModalClose}
        roundDuration={roundDuration}
        onRoundDurationChange={handleRoundDurationChange}
        vacantRanks={uniqueRanks.filter((rank) => mergedVacancies[rank.id])}
        onSubmit={handleVacancyFillOrderSubmit}
        currentOrder={vacancyRankCallOrder}
        activeAutoHireRanks={activeAutoHireRanks}
      />
    </Box>
  );
};
