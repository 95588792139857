/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  InputBase,
  Autocomplete,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Chip,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { parseISO } from 'date-fns';
import { useState, ChangeEvent } from 'react';
import {
  Modal,
  Pencil01Icon16,
  SnackbarService,
  theme,
  Trash04Icon20,
  useLoadedDepartmentInfoContext,
  PlusCircleIcon20,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';
import { WarningModal, QuestionModal } from '../../../../../../src/components/Common';
import { AddTimeEntryModal } from './AddTimeEntryModal';
import { EntryAction } from './types';
import { transformDetailedPayroll } from './utils';

const IMMUTABLE_COLUMNS = ['date', 'employee name', 'pay code', 'actual duration', 'duration', 'detail codes'];

interface EmployeePayrollTableProps {
  employeePayroll: EmployeePayroll;
  setChangeColumnNameOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedColumnName: React.Dispatch<React.SetStateAction<string>>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  hasChanges: (newData: any) => boolean;
  editingCell: { row: number; column: string } | null;
  setEditingCell: React.Dispatch<React.SetStateAction<{ row: number; column: string } | null>>;
  setNewData: React.Dispatch<React.SetStateAction<any | null>>;
  selectedPayPeriod: PayPeriod | null;
  forceRefetch: () => void;
  handlePressSave: () => void;
  setIsAddColumnModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryAction: (value: EntryAction | null) => void;
  payCodes: { code: string; name: string }[];
}

export const EmployeePayrollTable = ({
  employeePayroll,
  setChangeColumnNameOpen,
  setSelectedColumnName,
  isEditing,
  setIsEditing,
  hasChanges,
  editingCell,
  setEditingCell,
  setNewData,
  selectedPayPeriod,
  forceRefetch,
  handlePressSave,
  setIsAddColumnModalOpen,
  setEntryAction,
  payCodes,
}: EmployeePayrollTableProps) => {
  const [data, setData] = useState(transformDetailedPayroll(employeePayroll.detailedPayroll));
  const [warningModal, setWarningModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<number | null>(null);
  const [addTimeEntryModalOpen, setAddTimeEntryModalOpen] = useState(false);
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const detailCodes = departmentInfoState.departmentInfo.detailCodes.map((detailCode) => detailCode.name);

  const originalData = transformDetailedPayroll(employeePayroll.detailedPayroll);

  const capabilities = useAuthUserCapabilities();
  const canEditPayroll = capabilities.EDIT_ADMIN_PAYROLL;

  const handleChangeColumnName = (columnName: string) => {
    setChangeColumnNameOpen(true);
    setSelectedColumnName(columnName);
  };

  let columns = Object.keys(data[0] || {}).filter((key) => key !== 'deleteRow' && key !== 'isDeleted');
  columns = employeePayroll.payrollColumns.map((column) => column.toLowerCase());
  if (canEditPayroll) {
    columns.push('');
  }

  const clickAddNewTimeEntry = () => {
    setAddTimeEntryModalOpen(true);
  };
  const saveWarningModal = () => {
    handlePressSave();
    setWarningModal(false);
  };

  const handleCellClick = (rowIndex: number, column: string) => {
    if (editingCell && isEditing && (editingCell.row !== rowIndex || editingCell.column !== column)) {
      setWarningModal(true);
      return;
    }
    setEditingCell({ row: rowIndex, column });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any> | null,
    newValue: string | null,
    rowIndex: number,
    column: string,
  ) => {
    const newData = [...data];
    let value: string | number;

    if (e) {
      if (column === 'duration') {
        const inputValue = (e.target as HTMLInputElement).value;
        if (!/^[\d.]*$/.test(inputValue)) return;
        const parts = inputValue.split('.');
        if (parts.length > 2) return;
        if (parts[1] && parts[1].length > 2) return;
        value = inputValue;
      } else {
        value = (e.target as HTMLInputElement).value;
      }
    } else if (newValue !== null) {
      value = newValue;
    } else {
      return;
    }
    newData[rowIndex][column] = value;
    setData(newData);

    setEntryAction({
      action: 'edit_entry',
      date: originalData[rowIndex]['date'],
      payCode: originalData[rowIndex]['pay code'],
      columnName: column,
      oldValue: originalData[rowIndex][column],
      newValue: value,
    });

    if (hasChanges(newData) && value !== originalData[rowIndex][column]) {
      setIsEditing(true);
      setNewData(newData);
    } else {
      setIsEditing(false);
    }
  };

  const handleDeleteClick = (rowIndex: number) => {
    setRowToDelete(rowIndex);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (rowToDelete !== null) {
        const params = {
          action: 'delete_entry',
          date: data[rowToDelete]['date'],
          payCode: data[rowToDelete]['pay code'],
        };
        const response = await client.post(`payroll/${employeePayroll?.id}/entry-action/`, params);

        if (response.status === 200) {
          SnackbarService.notify({
            content: 'Row deleted successfully',
            severity: 'success',
            duration: 5000,
          });
          forceRefetch();
          setDeleteModalOpen(false);
        } else {
          SnackbarService.notify({
            content: 'Failed to save changes',
            severity: 'error',
            duration: 10000,
          });
          console.error('Failed to save changes', response);
        }
      }
    } catch (error) {
      captureException(error);
      console.error('An error occurred while saving changes', error);
    }
  };

  const isRowDeleted = (rowIndex: number) => {
    return data[rowIndex]['isDeleted'];
  };

  const isDateGreaterOrEqualToday = (rowIndex: number): boolean => {
    const today = new Date(new Date().toLocaleString('en-US', { timeZone: departmentInfoState.departmentInfo.timezone }));
    const [hours, minutes] = departmentInfoState.departmentInfo.shiftStart.split(':').map(Number);
    today.setHours(hours, minutes, 0, 0);
    const rowDate = parseISO(data[rowIndex]['date']);
    rowDate.setHours(hours, minutes, 0, 0);
    return rowDate >= today;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(8),
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: 0 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  align="left"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.stationGray[400],
                    boxShadow: 'none',
                    typography: 'tagline1',
                    justifyContent: 'space-between',
                    borderRight: column === '' ? 'none' : '1px solid',
                    borderColor: theme.palette.stationGray[200],
                    ':hover': {
                      backgroundColor: column === '' ? theme.palette.stationPurple[100] : theme.palette.stationGray[50],
                    },
                    height: theme.spacing(7),
                    padding: '12px 16px',
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      justifyContent: column === '' ? 'center' : 'space-between',
                      alignItems: 'center',
                      gap: theme.spacing(2),
                      minWidth: 'max-content',
                    })}
                  >
                    {column.toUpperCase().replace(/_/g, ' ')}
                    {canEditPayroll && column !== '' && !IMMUTABLE_COLUMNS.includes(column.toLowerCase()) && (
                      <Box
                        sx={(theme) => ({
                          color: theme.palette.stationGray[400],
                          cursor: 'pointer',
                          ':hover': {
                            backgroundColor: theme.palette.stationGray[200],
                            color: theme.palette.stationGray[800],
                          },
                          borderRadius: theme.spacing(1),
                          padding: theme.spacing(0.5),
                        })}
                        onClick={() => handleChangeColumnName(column.toUpperCase().replace(/_/g, ' '))}
                      >
                        <Pencil01Icon16 />
                      </Box>
                    )}
                    {column === '' ? (
                      <Tooltip
                        arrow
                        title="Add new column"
                        placement="top"
                        slotProps={{
                          tooltip: {
                            sx: {
                              bgcolor: theme.palette.stationGray[800],
                              typography: 'bodySMedium',
                              borderRadius: theme.spacing(1),
                              padding: '10px 12px',
                              gap: theme.spacing(1),
                              '& .MuiTooltip-arrow': {
                                color: theme.palette.stationGray[800],
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsAddColumnModalOpen(true)}
                        >
                          <PlusCircleIcon20
                            style={{
                              color: theme.palette.stationPurple[500],
                            }}
                          />
                        </Box>
                      </Tooltip>
                    ) : null}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={isRowDeleted(rowIndex) || isDateGreaterOrEqualToday(rowIndex) ? { pointerEvents: 'none' } : {}}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    align={column === '' ? 'center' : 'left'}
                    sx={{
                      boxShadow: 'none',
                      typography: 'bodyMRegular',
                      borderRight: column === '' ? 'none' : '1px solid',
                      borderColor: theme.palette.stationGray[200],
                      color: isRowDeleted(rowIndex)
                        ? theme.palette.stationGray[300]
                        : isDateGreaterOrEqualToday(rowIndex)
                          ? theme.palette.stationGray[500]
                          : theme.palette.common.black,
                      whiteSpace: 'nowrap',
                      height: theme.spacing(7),
                      padding: '0px 16px',
                      '&:hover': {
                        backgroundColor: theme.palette.stationGray[200],
                        color: theme.palette.stationPurple[500],
                      },
                    }}
                    onClick={() => handleCellClick(rowIndex, column)}
                  >
                    {canEditPayroll && column === 'pay code' ? (
                      <Autocomplete
                        options={payCodes}
                        value={payCodes.find((option) => option.code === row[column]) || undefined}
                        getOptionLabel={(option) => option.code}
                        isOptionEqualToValue={(option, value) => option.code === value?.code}
                        onChange={(_, newValue) => handleChange(null, newValue?.code, rowIndex, column)}
                        sx={{
                          minWidth: '100px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              boxShadow: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              boxShadow: 'none',
                            },
                            '& input': {
                              color: (theme) =>
                                isRowDeleted(rowIndex)
                                  ? theme.palette.stationGray[300]
                                  : isDateGreaterOrEqualToday(rowIndex)
                                    ? theme.palette.stationGray[500]
                                    : theme.palette.common.black,
                            },
                          },
                        }}
                        renderOption={(itemProps, option) => (
                          <li {...itemProps} key={itemProps.key}>
                            {option.code === option.name ? option.code : `${option.code} - ${option.name}`}
                          </li>
                        )}
                        renderInput={(params) => {
                          const selectedOption = payCodes.find((option) => option.code === row[column]);
                          const tooltipTitle = selectedOption
                            ? selectedOption.code === selectedOption.name
                              ? selectedOption.code
                              : `${selectedOption.code} - ${selectedOption.name}`
                            : row[column];
                          return (
                            <Tooltip title={tooltipTitle}>
                              <TextField {...params} />
                            </Tooltip>
                          );
                        }}
                        disableClearable
                      />
                    ) : canEditPayroll && column === 'detail codes' ? (
                      <Autocomplete
                        multiple
                        options={detailCodes}
                        value={row[column] ? row[column].split(',') : []}
                        onChange={(_event, newValue: string[]) => handleChange(null, newValue.join(','), rowIndex, column)}
                        renderTags={(value: string[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
                          ))
                        }
                        sx={{
                          minWidth: '100px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              boxShadow: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              boxShadow: 'none',
                            },
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : editingCell &&
                      canEditPayroll &&
                      editingCell.row === rowIndex &&
                      editingCell.column === column &&
                      column !== '' &&
                      column !== 'date' &&
                      column !== 'employee name' ? (
                      <InputBase
                        value={typeof row[column] === 'number' ? row[column].toFixed(2) : row[column]}
                        onChange={(e) => handleChange(e, null, rowIndex, column)}
                        autoFocus
                        fullWidth
                        sx={{
                          padding: 0,
                          margin: 0,
                          fontSize: 'inherit',
                          fontFamily: 'inherit',
                          color: 'inherit',
                          border: 'none',
                          outline: 'none',
                        }}
                        inputProps={{ maxLength: 80 }}
                      />
                    ) : column === '' ? (
                      isRowDeleted(rowIndex) || isDateGreaterOrEqualToday(rowIndex) ? null : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteClick(rowIndex)}
                          disabled={isEditing}
                          sx={{ padding: '0px' }}
                        >
                          <Trash04Icon20 />
                        </IconButton>
                      )
                    ) : typeof row[column] === 'number' ? (
                      row[column].toFixed(2)
                    ) : (
                      row[column]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          border: `1px dashed ${theme.palette.stationGray[200]}`,
          width: '100%',
          height: '48px',
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.stationPurple[500],
            typography: 'buttonL',
            backgroundColor: isEditing ? theme.palette.grey[200] : 'transparent',
            '&:hover': {
              backgroundColor: isEditing ? theme.palette.grey[200] : theme.palette.stationPurple[100],
            },
            cursor: isEditing ? 'not-allowed' : 'pointer',
            pointerEvents: isEditing ? 'none' : 'auto',
          })}
          onClick={isEditing ? undefined : clickAddNewTimeEntry}
        >
          + Add new time entry
        </Box>
      </Box>

      <Modal open={warningModal} setOpen={setWarningModal}>
        <WarningModal setModalOpen={setWarningModal} onConfirm={saveWarningModal} confirmButtonText="Save">
          <Box sx={{ width: '416px' }}>
            <Box sx={{ typography: 'bodyXXLSemibold' }}>You should save your changes before editing another cell.</Box>
          </Box>
        </WarningModal>
      </Modal>

      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <QuestionModal
          setModalOpen={setDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          secondButtonText="Remove"
          secondButtonBackgroundColor={theme.palette.stationRose[600]}
        >
          <Box sx={{ width: '416px', mb: theme.spacing(3) }}>
            <Box sx={{ typography: 'bodyXXLSemibold' }}>Do you really wish to remove the pay code?</Box>
            <Box sx={{ typography: 'bodyMRegular', mt: theme.spacing(2) }}>
              You cannot undo this action, but you can add another pay code anytime.
            </Box>
          </Box>
        </QuestionModal>
      </Modal>
      <AddTimeEntryModal
        addTimeEntryModalOpen={addTimeEntryModalOpen}
        setAddTimeEntryModalOpen={setAddTimeEntryModalOpen}
        selectedPayPeriod={selectedPayPeriod}
        data={data}
        employeePayroll={employeePayroll}
        forceRefetch={forceRefetch}
        payCodes={payCodes}
      />
    </Box>
  );
};
