import { setMinutes } from 'date-fns';
import {
  DepartmentInfo,
  RosterDataSource,
  RosterStation,
  RosterEmployeeSplit,
  RosterShiftDuration,
  ShiftSummary,
  ShiftSummaryPlannedAssignment,
  ShiftSummaryEmployee,
} from '@stationwise/share-types';
import { getPositionEmployeeSortKey } from './board/position';
import { setEmployeeActiveId } from './id';
import { getDepartmentPayCodes } from './payCode';
import { IShiftSummaryHelper } from './types';

interface GetShiftSummaryHelperInput {
  departmentInfo: DepartmentInfo;
  shiftSummary: ShiftSummary;
  shiftDuration: RosterShiftDuration;
}

const makeRosterEmployee = (input: GetShiftSummaryHelperInput, employee: ShiftSummaryEmployee, dataSource: RosterDataSource) => {
  return setEmployeeActiveId({
    ...employee,
    dataSource,
    startDateTime: new Date(employee.startDateTime),
    endDateTime: new Date(employee.endDateTime),
    payCodes: getDepartmentPayCodes(input.departmentInfo, employee.payCodes),
  });
};

const makeRosterEmployeeSplits = ({ shiftSummary, shiftDuration }: GetShiftSummaryHelperInput) => {
  const map = new Map<string, RosterEmployeeSplit[]>();
  Object.entries(shiftSummary.employeeSplits).forEach(([employeeId, splits]) => {
    const newSplits = splits.map(({ startTime, endTime, minStartTime, maxEndTime, ...split }) => ({
      ...split,
      startDateTime: setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + startTime),
      endDateTime: setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + endTime),
      minStartDateTime: setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minStartTime),
      maxEndDateTime: setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + maxEndTime),
    }));
    map.set(employeeId, newSplits);
  });
  return map;
};

/**
 *  This function converts backend ShiftSummaryStation data to frontend RosterStation data.
 * @param stations  - backend ShiftSummaryStation data
 * @param shiftDuration  - shift duration
 * @returns - frontend RosterStation data
 */
export const getAllStationCardsMap = (input: GetShiftSummaryHelperInput) => {
  const newMap = new Map<string, RosterStation>();
  input.shiftSummary.stations.forEach((station) => {
    const newStation = {
      ...station,
      stationId: String(station.stationId),
      dataSource: RosterDataSource.STATION,
      apparatuses: station.apparatuses.map((apparatus) => {
        return {
          ...apparatus,
          id: String(apparatus.id),
          dataSource: RosterDataSource.STATION,
          positions: apparatus.positions.map((position) => {
            const temporaryEmployee = position.isTemporary && position.employees[0];
            return {
              ...position,
              id: String(position.id),
              dataSource: RosterDataSource.STATION,
              startDateTime: temporaryEmployee ? new Date(temporaryEmployee.startDateTime) : input.shiftDuration.startTime,
              endDateTime: temporaryEmployee ? new Date(temporaryEmployee.endDateTime) : input.shiftDuration.endTime,
              employees: position.employees
                .filter((employee) => !!employee.id)
                .map((employee) => makeRosterEmployee(input, employee, RosterDataSource.STATION))
                .sort(getPositionEmployeeSortKey),
            };
          }),
        };
      }),
    };
    newMap.set(String(station.stationId), newStation);
  });
  return newMap;
};

export const getPlannedAssignmentsMap = (plannedAssignments: ShiftSummaryPlannedAssignment[]) => {
  const map = new Map<string, ShiftSummaryPlannedAssignment[]>();
  plannedAssignments.forEach((assignment) => {
    const employeeId = `${assignment.employeeId}`;
    const assignments: ShiftSummaryPlannedAssignment[] = map.get(employeeId) || [];
    assignments.push(assignment);
    map.set(employeeId, assignments);
  });
  return map;
};

/**
 *  Get shift summary helper object from shift summary
 * @param shiftSummary - shift summary
 * @param shiftDuration - shift duration
 * @returns - shift summary helper
 */
export const getShiftSummaryHelper = (input: GetShiftSummaryHelperInput): IShiftSummaryHelper => {
  return {
    departmentInfo: input.departmentInfo,
    unassignedCards: input.shiftSummary.floaters.map((floater) => makeRosterEmployee(input, floater, RosterDataSource.FLOATER)),
    allStationCards: getAllStationCardsMap(input),
    cancelShiftTradePayloads: [],
    cancelTimeOffPayloads: [],
    plannedAssignments: getPlannedAssignmentsMap(input.shiftSummary.plannedAssignments),
    unplannedAssignees: [],
    employeeSplits: makeRosterEmployeeSplits(input),
    shiftDuration: input.shiftDuration,
    kellyDayEmployeeIds: input.shiftSummary.kellyDayEmployeeIds,
    currentTeams: input.shiftSummary.shiftTeams,
    shiftColor: input.shiftSummary.shiftColor,
    currentBattalion: {
      id: input.shiftSummary.battalion.id,
      name: input.shiftSummary.battalion.name,
      stations: [],
    },
    createdAt: new Date().toISOString(),
  };
};
