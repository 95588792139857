import { ReactComponent as UploadFileSymbol } from '@material-symbols/svg-400/outlined/upload_file.svg';
import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { SnackbarService, SvgIcon } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { PayCode } from '@stationwise/share-types';

type TimeOffChange = {
  employeeEmail: string;
  shiftDate: string;
  payCode: string;
  partialStartTime: string;
  partialEndTime: string;
};

export const UploadBox = ({
  setFileDetails,
  uploadType,
  setAccrualChanges,
  setTimeOffChanges,
  setUploadStatus,
  handleDelete,
  setParsedAccruals,
}: {
  setFileDetails: React.Dispatch<
    React.SetStateAction<{
      name: string;
      size: string;
    } | null>
  >;
  uploadType: 'accruals' | 'time-off';
  setAccrualChanges: React.Dispatch<
    React.SetStateAction<
      {
        employeeName: string;
        email: string;
        payCode: PayCode;
        oldValue: number;
        newValue: number;
      }[]
    >
  >;
  setTimeOffChanges: React.Dispatch<
    React.SetStateAction<
      {
        employeeEmail: string;
        payCode: string;
        shiftDate: string;
        partialStartTime: string;
        partialEndTime: string;
        new: boolean;
      }[]
    >
  >;
  setUploadStatus: React.Dispatch<React.SetStateAction<'loading' | 'complete' | null>>;
  handleDelete: () => void;
  setParsedAccruals: React.Dispatch<
    React.SetStateAction<
      {
        employeeEmail: string;
        payCode: string;
        accruedTimeOff: number;
      }[]
    >
  >;
}) => {
  const handleFile = (file: File) => {
    if (file && file.type === 'text/csv') {
      setFileDetails({ name: file.name, size: `${file.size} bytes` });
      setUploadStatus('loading');

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target?.result as string;
        const rows = content.split('\n');

        try {
          if (uploadType === 'accruals') {
            const parsedAccruals = rows
              .slice(1)
              .filter((row) => row.trim())
              .map((row) => {
                const [timeOffCode, hoursRemaining, email] = row.split(',').map((val) => val.trim());
                return {
                  employeeEmail: email,
                  payCode: timeOffCode,
                  accruedTimeOff: hoursRemaining === 'Unlimited' ? 1e308 : parseFloat(hoursRemaining),
                };
              });
            setParsedAccruals(parsedAccruals);
            const response = await client.post('/employee/accruals/compare/', { imported_accruals: parsedAccruals });
            setAccrualChanges(response.data.changes);
          } else {
            const today = new Date();
            const parsedTimeOffs = rows
              .slice(1)
              .filter((row) => {
                if (!row.trim()) return false;
                const [, , shiftDate] = row.split(',').map((val) => val.trim());
                const shiftDateObj = new Date(shiftDate);
                return shiftDateObj > today;
              })
              .map((row) => {
                const [employeeEmail, payCode, shiftDate, partialStartTime, partialEndTime] = row
                  .split(',')
                  .map((val) => val.trim());
                return {
                  employeeEmail,
                  payCode,
                  shiftDate,
                  partialStartTime,
                  partialEndTime,
                  new: true,
                };
              });

            const uniqueEmails = Array.from(new Set(parsedTimeOffs.map((off) => off.employeeEmail)));
            const response = await client.get('/request/time-off-request/future-time-offs/', {
              params: { emails: uniqueEmails },
            });
            const existingTimeOffs = response.data.map((timeOff: TimeOffChange) => ({
              employeeEmail: timeOff.employeeEmail,
              shiftDate: timeOff.shiftDate,
              payCode: timeOff.payCode,
              partialStartTime: timeOff.partialStartTime,
              partialEndTime: timeOff.partialEndTime,
              new: false,
            }));
            setTimeOffChanges([...existingTimeOffs, ...parsedTimeOffs]);
          }
          setUploadStatus('complete');
        } catch (error) {
          captureException(error);
          SnackbarService.notify({
            content: 'Error processing the CSV file',
            severity: 'error',
            duration: 5000,
          });
          handleDelete();
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        mx: theme.spacing(3),
        border: '2px dashed',
        borderColor: theme.palette.grey[300],
        borderRadius: theme.spacing(1),
        p: theme.spacing(4),
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.grey[50],
        },
      })}
      onDrop={(e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <SvgIcon component={UploadFileSymbol} sx={(theme) => ({ fontSize: '24px', color: theme.palette.primary.main })} />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.csv';
            input.onchange = (e) => {
              const file = (e.target as HTMLInputElement).files?.[0];
              if (file) handleFile(file);
            };
            input.click();
          }}
          sx={(theme) => ({
            typography: 'subtitle1',
            mb: 1,
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            '&:hover': {
              color: theme.palette.primary.dark,
            },
          })}
        >
          Click to upload
        </Typography>
        <Box sx={(theme) => ({ width: theme.spacing(0.5) })} />
        <Typography sx={{ typography: 'subtitle1', mb: 1 }}>or drag and drop </Typography>
      </Box>
      <Typography sx={(theme) => ({ typography: 'bodyXSRegular', color: theme.palette.grey[500] })}>
        {'CSV file (max. 3MB)'}
      </Typography>
    </Box>
  );
};
