import { Box, Modal } from '@mui/material';
import { Button, QuestionMarkIcon24 } from '@stationwise/component-module';

interface RemoveWorkGroupModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  header: string;
  subtext?: string;
  handleRemove: () => void;
}

export const RemoveWorkGroupModal = ({ showModal, setShowModal, header, subtext, handleRemove }: RemoveWorkGroupModalProps) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={(theme) => ({
              width: '56px',
              height: '56px',
              borderRadius: '80px',
              margin: 'auto',
              border: `1px solid ${theme.palette.stationGray[50]}`,
              boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: theme.spacing(2),
            })}
          >
            <Box
              sx={(theme) => ({
                color: theme.palette.stationPurple[500],
              })}
            >
              <QuestionMarkIcon24 />
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', margin: 'auto' }}>
            <Box sx={{ typography: 'bodyXLSemibold' }}>{header} </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[500],
                my: theme.spacing(2),
              })}
            >
              <Box sx={{ typography: 'bodyMRegular' }}>{subtext}</Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(3),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              sx={() => ({
                width: '182px',
                height: '44px',
                padding: '9px 17px 9px 17px',
                borderRadius: 2,
              })}
              onClick={() => handleClose()}
            >
              <Box component="span" sx={{ typography: 'buttonL' }}>
                Cancel
              </Box>
            </Button>
            <Button
              sx={(theme) => ({
                width: '182px',
                height: '44px',
                padding: '9px 17px 9px 17px',
                backgroundColor: theme.palette.stationRose[600],
                color: theme.palette.common.white,
                borderRadius: 2,
                '&:hover': { backgroundColor: theme.palette.stationRose[700], boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              onClick={() => handleRemove()}
            >
              <Box component="span" sx={{ typography: 'buttonL' }}>
                Remove
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
