import { Box } from '@mui/material';
import { addDays, differenceInDays, format, parseISO } from 'date-fns';
import React, { useState, useEffect, useMemo, FunctionComponent, SVGProps } from 'react';
import {
  Button,
  GridIcon20,
  Server01Icon20,
  theme,
  useLoadedDepartmentInfoContext,
  useAuthUserContext,
  OrangeCircleWarningIcon20,
  CircleTickCIcon20,
  PayPeriodPicker,
  getDepartmentFeatureFlagValue,
} from '@stationwise/component-module';
import { PayPeriod, PayrollCode, PayrollData, SignedState } from '@stationwise/share-types';
import { useCompTimeRequest } from '../hooks/useCompTimeRequest';
import { PayrollCards } from './PayrollCards';
import { PayrollCompTimeAccrualModal } from './PayrollCompTimeAccrualModal';
import { PayrollTable } from './PayrollTable';
import { PayrollValidation } from './PayrollValidation';
import { PayrollValidationModal } from './PayrollValidationModal';
import { AvailableCompTime } from './utils';

const PAYROLL_VIEWS = {
  TABLE: 'table',
  CARD: 'card',
};

interface PayrollContentProps {
  startDate: Date;
  endDate: Date;
  payroll: PayrollData;
  selectedPayPeriod: PayPeriod | null;
  setSelectedPayPeriod: React.Dispatch<React.SetStateAction<PayPeriod | null>>;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
}

export const PayrollContent = ({
  startDate,
  endDate,
  payroll,
  selectedPayPeriod,
  setSelectedPayPeriod,
  setRefetchCounter,
  refetchCounter,
}: PayrollContentProps) => {
  const [activeView, setActiveView] = useState(PAYROLL_VIEWS.CARD);
  const [openCompTimeModal, setOpenCompTimeModal] = useState(false);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [availableCompTimes, setAvailableCompTimes] = useState<AvailableCompTime[]>();
  const { loading, error, handleRequestCompTime } = useCompTimeRequest(setAvailableCompTimes);
  const [payrollCode, setPayrollCode] = useState<PayrollCode | null>(null);
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const departmentInfo = departmentContext.departmentInfo;
  const { state: authUserContext } = useAuthUserContext();
  const userName = authUserContext.employee?.firstName + ' ' + authUserContext.employee?.lastName;
  const [showPayrollValidation, setShowPayrollValidation] = useState<boolean>(false);
  const [showPayrollValidationModal, setShowPayrollValidationModal] = useState(false);
  const [leftIcon, setLeftIcon] = useState<FunctionComponent<SVGProps<SVGSVGElement>>>(() => OrangeCircleWarningIcon20);
  const [payrollValidationMessage, setPayrollValidationMessage] = useState('');
  const [currentPeriodIndex, setCurrentPeriodIndex] = useState<number>(0);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(0);

  const payrollValidationEnabled = getDepartmentFeatureFlagValue(departmentContext, 'PAYROLL_VALIDATION_ENABLED', true);
  useEffect(() => {
    if (payrollValidationEnabled && (payroll.signedState === SignedState.SIGNED || payroll.isSignable)) {
      setShowPayrollValidation(true);
      if (payroll.signedState === SignedState.SIGNED) {
        const formattedDate = format(payroll.signedAt, "MMM d, yyyy 'at' HH:mm");
        setPayrollValidationMessage('Signed ' + formattedDate);
        setLeftIcon(() => CircleTickCIcon20);
      } else {
        setPayrollValidationMessage("It's time to sign your time card.");
        setLeftIcon(() => OrangeCircleWarningIcon20);
      }
    } else {
      setShowPayrollValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payroll]);

  const openPayrollValidationModal = () => {
    if (payroll.isSigned) return;
    setShowPayrollValidationModal(true);
  };
  const days = useMemo(() => {
    const daysBetween = differenceInDays(endDate, startDate);
    const days = [];
    for (let i = 0; i <= daysBetween; i++) {
      days.push(addDays(parseISO(startDate.toISOString()), i));
    }
    return days;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleReset = () => {
    setSelectedPayPeriod({
      startDate: payroll.currentPayPeriod.startDate,
      endDate: payroll.currentPayPeriod.endDate,
      duration: payroll.currentPayPeriod.duration,
      payPeriodType: payroll.currentPayPeriod.payPeriodType,
      id: payroll.currentPayPeriod.id,
    });
    setSelectedPeriodIndex(currentPeriodIndex);
  };

  return (
    <>
      <Box
        sx={{
          paddingX: theme.spacing(2),
          marginBottom: theme.spacing(2),
          borderRadius: '0px 0px 12px 12px',
          border: `0px, 0px, 1px, 0px, ${theme.palette.stationGray[100]}`,
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(2.5),
          })}
        >
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              typography: 'bodyXXLSemibold',
            })}
          >
            My Payroll
          </Box>
          <Button
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              border: `1px solid ${theme.palette.stationGray[300]}`,
              borderRadius: '6px',
              height: theme.spacing(4),
              display: selectedPayPeriod?.startDate === payroll.currentPayPeriod.startDate ? 'none' : 'auto',
              color: theme.palette.stationGray[700],
              padding: '9px, 13px, 9px, 13px',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
              },
            })}
            onClick={handleReset}
          >
            {'Back to current period'}
          </Button>
        </Box>
        <Box sx={{ marginTop: theme.spacing(3) }}>
          <PayPeriodPicker
            selectedPayPeriod={selectedPayPeriod}
            setSelectedPayPeriod={setSelectedPayPeriod}
            currentPayPeriod={payroll.currentPayPeriod}
            setCurrentPeriodIndex={setCurrentPeriodIndex}
            selectedPeriodIndex={selectedPeriodIndex}
            setSelectedPeriodIndex={setSelectedPeriodIndex}
          />
        </Box>
        {showPayrollValidation && (
          <PayrollValidation onClick={openPayrollValidationModal} leftIcon={leftIcon} message={payrollValidationMessage} />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: theme.spacing(1.5),
          px: theme.spacing(2),
        })}
      >
        <Box sx={{ typography: 'bodyLSemibold' }}>Time Card</Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
          })}
        >
          <Box
            onClick={() => {
              activeView === PAYROLL_VIEWS.TABLE ? setActiveView(PAYROLL_VIEWS.CARD) : setActiveView(PAYROLL_VIEWS.TABLE);
            }}
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              p: '5px',
              borderRadius: theme.spacing(5),
              cursor: 'pointer',
            })}
          >
            <Box
              data-cy="time-card-table-view"
              sx={(theme) => ({
                backgroundColor: activeView === PAYROLL_VIEWS.TABLE ? theme.palette.stationGray[900] : theme.palette.common.white,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  stroke: activeView === PAYROLL_VIEWS.TABLE ? theme.palette.common.white : theme.palette.stationGray[900],
                },
              })}
            >
              <GridIcon20 />
            </Box>
            <Box
              data-cy="time-card-card-view"
              sx={(theme) => ({
                backgroundColor: activeView === PAYROLL_VIEWS.CARD ? theme.palette.stationGray[900] : theme.palette.common.white,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  stroke: activeView === PAYROLL_VIEWS.CARD ? theme.palette.common.white : theme.palette.stationGray[900],
                },
              })}
            >
              <Server01Icon20 />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          px: theme.spacing(2),
        })}
      >
        {activeView === PAYROLL_VIEWS.TABLE ? (
          <PayrollTable days={days} payroll={payroll} />
        ) : (
          <PayrollCards
            days={days}
            payroll={payroll}
            setOpenCompTimeModal={setOpenCompTimeModal}
            handleRequestCompTime={handleRequestCompTime}
            setPayrollCode={setPayrollCode}
          />
        )}
        {availableCompTimes && (
          <PayrollCompTimeAccrualModal
            setOpenCompTimeModal={setOpenCompTimeModal}
            openCompTimeModal={openCompTimeModal}
            setOpenResponseModal={setOpenResponseModal}
            openResponseModal={openResponseModal}
            availableCompTimes={availableCompTimes}
            payrollCode={payrollCode}
            loading={loading}
            requestCompTimeError={error}
            setRefetchCounter={setRefetchCounter}
            refetchCounter={refetchCounter}
          />
        )}
      </Box>
      {showPayrollValidationModal && (
        <PayrollValidationModal
          showPayrollValidationModal={showPayrollValidationModal}
          setShowPayrollValidationModal={setShowPayrollValidationModal}
          departmentName={departmentInfo.name}
          userName={userName}
          days={days}
          payroll={payroll}
          startDate={selectedPayPeriod?.startDate}
          endDate={selectedPayPeriod?.endDate}
          payPeriodId={selectedPayPeriod?.id}
          setRefetchCounter={setRefetchCounter}
          refetchCounter={refetchCounter}
        />
      )}
    </>
  );
};
