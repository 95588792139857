import { Box, Button } from '@mui/material';
import { addHours, differenceInHours, format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RequestDurationOptions } from '@stationwise/component-module';
import { StartTime } from '@stationwise/share-types';
import { generateStartTimes } from '@stationwise/share-utils';
import { useIOSPaddingBottom, useIsIOSWebView } from '../../../../../context/DeviceInfoContext';
import { RequestStartTimes } from '../RequestSharedComponents/RequestStartTimes';
import { ShiftTradeBalance } from './ShiftTradeBalance';

interface ShiftTradeDurationProps {
  startDateTime: string;
  setStartDateTime: Dispatch<SetStateAction<string>>;
  endDateTime: string;
  setEndDateTime: Dispatch<SetStateAction<string>>;
  originalEndDateTime: string;
  originalStartDateTime: string;
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  handleNextStep: () => void;
}

const shiftTradeDurationOptions: { label: string; value: number }[] = [
  { label: '4 hrs', value: 4 },
  { label: '8 hrs', value: 8 },
  { label: '12 hrs', value: 12 },
  { label: '16 hrs', value: 16 },
  { label: '20 hrs', value: 20 },
  { label: 'Entire Shift', value: 24 },
];

export const ShiftTradeDuration = ({
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
  duration,
  setDuration,
  originalEndDateTime,
  originalStartDateTime,
  handleNextStep,
}: ShiftTradeDurationProps) => {
  const [shiftTradeStartOptions, setShiftTradeStartOptions] = useState<StartTime[]>([]);

  const isIOSWebView = useIsIOSWebView();
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  useEffect(() => {
    const maxDuration = Math.abs(differenceInHours(startDateTime, originalEndDateTime, { roundingMethod: 'ceil' }));
    const result = generateStartTimes(format(startDateTime, 'HH:mm'), maxDuration);

    if (duration !== maxDuration) {
      setDuration(maxDuration);
    }

    setShiftTradeStartOptions(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEndDateTime(addHours(startDateTime, duration).toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTime, duration]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={(theme) => ({ mr: theme.spacing(2) })}>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyLSemibold' })}>Shift trade start</Box>
        <RequestStartTimes
          originalStartDateTime={originalStartDateTime}
          startDateTime={startDateTime}
          setStartDateTime={setStartDateTime}
          startOptions={shiftTradeStartOptions}
          setDuration={setDuration}
          title="Shift trade start"
        />
        <Box
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            mt: theme.spacing(4),
            typography: 'bodyLSemibold',
          })}
        >
          Shift trade duration
        </Box>
      </Box>
      <RequestDurationOptions
        durationOptions={shiftTradeDurationOptions}
        startDateTime={startDateTime}
        duration={duration}
        setDuration={setDuration}
        originalEndDateTime={originalEndDateTime}
        originalStartDateTime={originalStartDateTime}
      />
      <Box
        sx={(theme) => ({
          position: 'sticky',
          bottom: 0,
          width: '100%',
          marginTop: theme.spacing(5),
          padding: theme.spacing(2),
          paddingBottom: isIOSWebView ? iosWebViewPaddingBottom : 'none',
          border: `1px solid ${theme.palette.stationGray[200]}`,
          borderRadius: 2,
          boxShadow: '0px -10px 10px -5px rgba(10, 14, 22, 0.04), 0px -20px 25px -5px rgba(10, 14, 22, 0.1)',
          backgroundColor: theme.palette.common.white,
        })}
      >
        <ShiftTradeBalance startDateTime={startDateTime} endDateTime={endDateTime} duration={duration} />
        <Box
          sx={(theme) => ({
            mr: theme.spacing(1),
            mt: theme.spacing(2),
          })}
        >
          <Button
            data-cy="select-people-to-trade-with-button"
            variant="contained"
            sx={(theme) => ({
              background: theme.palette.stationGray[800],
              width: '100%',
              textTransform: 'none',
              borderRadius: '6px',
            })}
            onClick={handleNextStep}
            disabled={duration === 0}
          >
            <Box component="span" sx={(theme) => ({ color: theme.palette.common.white, typography: 'bodyMMedium' })}>
              Select people to trade with
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
