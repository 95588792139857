import { Box } from '@mui/material';

interface SelectionBoxProps {
  isSelected: boolean;
  onClick: () => void;
  label: string;
}

export const SelectionBox = ({ isSelected, onClick, label }: SelectionBoxProps) => {
  return (
    <Box
      sx={(theme) => ({
        borderRadius: theme.spacing(1),
        border: '1px solid',
        borderColor: theme.palette.stationGray[200],
        background: isSelected ? theme.palette.stationGray[900] : theme.palette.common.white,
        justifyContent: 'center',
        alignContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        color: isSelected ? theme.palette.common.white : theme.palette.stationGray[900],
        typography: 'bodyMRegular',
        paddingX: theme.spacing(1.5),
        gap: theme.spacing(1),
        whiteSpace: 'nowrap',
      })}
      onClick={onClick}
    >
      {label}
    </Box>
  );
};
