import { Box, Button } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { BANK_HOURS_THRESHOLD } from '@stationwise/component-module';
import { PayrollCode, PayrollHours } from '@stationwise/share-types';
import { PayrollCardDrawer } from './PayrollCardDrawer';
import { PayrollStatusBadge } from './PayrollStatusBadge';

const isFutureTimeEntry = (dayHours: PayrollHours) => {
  return parseISO(dayHours.date).getDate() >= new Date().getDate();
};

interface PayrollCardItemProps {
  currentDate: Date;
  dayHours: PayrollHours;
  payCode: PayrollCode;
  setOpenCompTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRequestCompTime: (day: Date, overtimeCode: string) => void;
  setPayrollCode: React.Dispatch<React.SetStateAction<PayrollCode | null>>;
}

export const PayrollCardItem = ({
  currentDate,
  dayHours,
  payCode,
  setOpenCompTimeModal,
  handleRequestCompTime,
  setPayrollCode,
}: PayrollCardItemProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  return (
    <>
      <Box
        sx={(theme) => ({
          p: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          borderBottom: `1px solid ${theme.palette.stationGray[100]}`,
          ml: '4px',
        })}
        onClick={() => {
          setDrawerIsOpen(true);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '40px 1fr',
              gap: theme.spacing(2),
              typography: 'bodyMRegular',
              color: isFutureTimeEntry(dayHours) ? 'text.secondary' : 'text.primary',
            })}
          >
            <Box>{format(currentDate, 'E').toUpperCase()}</Box>
            <Box>{format(currentDate, 'MMM dd')}</Box>
          </Box>
          <Box>{dayHours?.status && dayHours.hours > 0 && <PayrollStatusBadge status={dayHours.status} />}</Box>
          <Box
            sx={(theme) => ({
              pr: theme.spacing(5),
              color: isFutureTimeEntry(dayHours) ? 'text.secondary' : 'text.primary',
              typography: 'bodyXLMedium',
            })}
          >
            {dayHours !== undefined ? `${dayHours.hours.toFixed(1)} h` : '0.0 h'}
          </Box>
        </Box>
        {payCode.isConvertible &&
          dayHours?.hours &&
          dayHours.canConvert &&
          dayHours.hours >= BANK_HOURS_THRESHOLD &&
          payCode.payCodeType === 'OVERTIME' && (
            <Button
              sx={(theme) => ({
                mt: theme.spacing(1),
                border: `1px solid ${theme.palette.stationGray[300]}`,
                borderRadius: '6px',
                height: theme.spacing(4),
                color: theme.palette.stationGray[700],
                typography: 'buttonM',
                padding: '9px 13px',
              })}
              onClick={(e) => {
                e.stopPropagation();
                setOpenCompTimeModal(true);
                handleRequestCompTime(currentDate, payCode.code);
                setPayrollCode(payCode);
              }}
            >
              Convert to comp time
            </Button>
          )}
      </Box>
      {drawerIsOpen && dayHours?.reviewedAt && dayHours.reviewedBy && (
        <PayrollCardDrawer drawerIsOpen={drawerIsOpen} dayHours={dayHours} payCode={payCode} setDrawerIsOpen={setDrawerIsOpen} />
      )}
    </>
  );
};
