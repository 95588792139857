import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Button, Typography } from '@mui/material';
import { NoShifts } from '@stationwise/component-module';

interface ButtonAPTProps {
  handleEmptyDayAdditionalPaidTimeClick?: (shift: EventInput | EventImpl) => void;
  selectedDate: Date;
  viewOnly?: boolean;
}

export const ButtonAPT = ({ viewOnly = false, handleEmptyDayAdditionalPaidTimeClick, selectedDate }: ButtonAPTProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      transform: 'translate(0, 100%)',
    }}
  >
    <NoShifts />
    <Box sx={(theme) => ({ my: theme.spacing(2), typography: 'h6' })}>No shifts or events on this day</Box>

    {viewOnly ||
      (!(selectedDate > new Date()) && (
        <Button
          data-cy="apt-request-button"
          variant="outlined"
          color="primary"
          onClick={() =>
            handleEmptyDayAdditionalPaidTimeClick &&
            handleEmptyDayAdditionalPaidTimeClick({
              start: selectedDate,
              end: selectedDate,
            })
          }
          sx={(theme) => ({
            overflow: 'auto',
            backgroundColor: theme.palette.primary.main,
            border: '1px solid solid',
            padding: '9px 17px 9px 17px',
            borderRadius: '6px',
            borderColor: theme.palette.stationGray[300],
          })}
        >
          <Typography variant="buttonL" sx={(theme) => ({ textTransform: 'none', color: theme.palette.common.white })}>
            Request additional paid time
          </Typography>
        </Button>
      ))}
  </Box>
);
