import { Button, Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EmployeePayrollNavigation } from '@stationwise/share-types';
import { ChevronLeftIcon24, ChevronRightIcon24 } from '../../assets';

interface EmployeePickerProps {
  employeePayrollNavigation: EmployeePayrollNavigation | null;
}

export const EmployeePicker: React.FC<EmployeePickerProps> = ({ employeePayrollNavigation }) => {
  const navigate = useNavigate();

  const handleNextEmployeePayroll = () => {
    navigate(`/app/payroll/employee/${employeePayrollNavigation?.nextEmployee?.payrollId}`);
  };

  const handlePreviousEmployeePayroll = () => {
    navigate(`/app/payroll/employee/${employeePayrollNavigation?.prevEmployee?.payrollId}`);
  };

  const limitNameLength = (name: string, maxLength = 18): string => {
    return name.length > maxLength ? name.substring(0, maxLength - 3) + '...' : name;
  };

  return (
    <Box
      sx={(theme) => ({
        paddingBottom: theme.spacing(0),
      })}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        {employeePayrollNavigation?.prevEmployee && (
          <Button
            onClick={handlePreviousEmployeePayroll}
            sx={(theme) => ({
              color: theme.palette.common.black,
              backgroundColor: theme.palette.common.white,
              typography: 'bodyMMedium',
              padding: '8px 16px 8px 8px',
              borderRadius: theme.spacing(2.5),
              '&:hover': {
                backgroundColor: theme.palette.stationGray[200],
              },
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
              display: 'flex',
              justifyContent: 'space-between',
            })}
          >
            <ChevronLeftIcon24 />
            {limitNameLength(employeePayrollNavigation.prevEmployee.name)}
          </Button>
        )}
        {employeePayrollNavigation?.nextEmployee && (
          <Button
            onClick={handleNextEmployeePayroll}
            sx={(theme) => ({
              color: theme.palette.common.black,
              backgroundColor: theme.palette.common.white,
              typography: 'bodyMMedium',
              padding: theme.spacing(1, 1, 1, 2),
              borderRadius: theme.spacing(2.5),
              '&:hover': {
                backgroundColor: theme.palette.stationGray[200],
              },
              marginLeft: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
            })}
          >
            {limitNameLength(employeePayrollNavigation.nextEmployee.name)}
            <ChevronRightIcon24 />
          </Button>
        )}
      </Box>
    </Box>
  );
};
