import { ReactComponent as CloseSymbol } from '@material-symbols/svg-400/outlined/close.svg';
import { ReactComponent as DragIndicatorSymbol } from '@material-symbols/svg-400/outlined/drag_indicator.svg';
import { Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, theme, SnackbarService, SvgIcon } from '@stationwise/component-module';
import { Rank } from '@stationwise/share-types';
import { AutoHireIntervalPicker } from './AutoHireIntervalPicker';

interface AutoHireDaySettingsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  roundDuration: number;
  onRoundDurationChange: (duration: number) => void;
  vacantRanks: Rank[];
  activeAutoHireRanks: Rank[];
  onSubmit: (newOrders: number[]) => void;
  currentOrder: number[];
}

export const AutoHireDaySettings: React.FC<AutoHireDaySettingsProps> = ({
  open,
  setOpen,
  roundDuration,
  onRoundDurationChange,
  vacantRanks,
  activeAutoHireRanks,
  onSubmit,
  currentOrder,
}) => {
  const [orders, setOrders] = useState<number[]>(currentOrder);
  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  const [tempRoundDuration, setTempRoundDuration] = useState(roundDuration);

  useEffect(() => {
    const activeAutoHireSet = new Set(activeAutoHireRanks.map((rank) => rank.id));
    const vacantRanksSet = new Set(vacantRanks.map((rank) => rank.id));
    const filteredOrders = currentOrder.filter((rankId) => vacantRanksSet.has(rankId) && !activeAutoHireSet.has(rankId));

    if (filteredOrders.length !== currentOrder.length) {
      setOrders(filteredOrders);
    }
  }, [currentOrder, vacantRanks, activeAutoHireRanks, setOrders]);

  useEffect(() => {
    setTempRoundDuration(roundDuration);
  }, [roundDuration]);

  const handleSort = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const updatedOrders = [...orders];
      const draggedItemContent = updatedOrders.splice(dragItem.current, 1)[0];
      updatedOrders.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setOrders(updatedOrders);
    }
  };

  const handleSubmit = () => {
    onRoundDurationChange(tempRoundDuration);
    SnackbarService.notify({
      content: 'Auto hire settings are updated',
      severity: 'success',
      duration: 2000,
    });
    onSubmit(orders);
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen} hideCloseIcon>
      <Box
        sx={{
          p: theme.spacing(2),
          borderRadius: '8px',
          mx: 'auto',
          position: 'relative',
          maxWidth: '320px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '85vh',
          overflow: 'auto',
        }}
      >
        <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', top: theme.spacing(1), right: theme.spacing(1) }}>
          <SvgIcon component={CloseSymbol} />
        </IconButton>
        <Typography variant="h5">Auto Hire Settings</Typography>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            mt: theme.spacing(2),
            mb: theme.spacing(2),
            p: theme.spacing(1.5),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.stationBlue[100],
            border: `1px solid ${theme.palette.stationBlue[200]}`,
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={(theme) => ({
                color: theme.palette.stationBlue[700],
                typography: 'bodySRegular',
              })}
            >
              These settings are specific to this day.
            </Typography>
          </Box>
        </Box>

        {/* Round Duration */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: theme.spacing(2),
          }}
        >
          <AutoHireIntervalPicker
            value={tempRoundDuration.toString()}
            onChange={(newValue) => {
              if (newValue) {
                const totalMinutes = parseInt(newValue);
                setTempRoundDuration(totalMinutes);
              }
            }}
            disabled={activeAutoHireRanks.length > 0}
            title={activeAutoHireRanks.length > 0 ? 'There is still an active auto hire for this day' : ''}
            sx={{
              width: '120px',
              '& .MuiInputBase-input': {
                fontSize: '16px',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '24px',
              },
            }}
          />
        </Box>
        <Divider sx={{ my: theme.spacing(2) }} />
        <Typography
          variant="bodyMRegular"
          sx={{
            color: theme.palette.stationGray[600],
            mb: theme.spacing(0.5),
            ml: theme.spacing(1),
            fontWeight: 600,
          }}
        >
          Auto Hire Rank Call Order
        </Typography>
        <Typography
          variant="bodySRegular"
          sx={{ color: theme.palette.stationGray[600], mb: theme.spacing(0.5), ml: theme.spacing(1), fontWeight: 400 }}
        >
          Auto hire will notify your selected rank groups in the order they are listed below.
        </Typography>
        <Box sx={{ flex: 1, overflow: 'auto', my: 2 }}>
          {activeAutoHireRanks.map((rank) => (
            <Box
              key={`autohire-${rank.id}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 1,
                backgroundColor: theme.palette.stationGray[100],
                p: theme.spacing(1),
                width: '280px',
                borderRadius: theme.spacing(1),
                opacity: 0.7,
                cursor: 'not-allowed',
              }}
            >
              <SvgIcon
                component={DragIndicatorSymbol}
                sx={{
                  marginRight: theme.spacing(1),
                  color: theme.palette.stationGray[400],
                }}
              />

              <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.stationGray[400] }}>-</Typography>
              <Typography sx={{ flex: 1, ml: theme.spacing(1.5), color: theme.palette.stationGray[600] }}>
                {rank.name} (Active)
              </Typography>
            </Box>
          ))}

          {orders.map((order, index) => {
            const rank = vacantRanks.find((rank) => rank.id === order);
            return (
              <Box
                key={index}
                draggable
                onDragStart={() => (dragItem.current = index)}
                onDragEnter={() => (dragOverItem.current = index)}
                onDragEnd={handleSort}
                onDragOver={(e) => e.preventDefault()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  backgroundColor: theme.palette.stationGray[50],
                  p: theme.spacing(1),
                  width: '280px',
                  borderRadius: theme.spacing(1),
                  border: '1px solid',
                  borderColor: dragItem.current === index ? theme.palette.primary.light : 'transparent',
                  opacity: 1,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[300],
                    cursor: 'move',
                  },
                }}
              >
                <SvgIcon
                  component={DragIndicatorSymbol}
                  sx={{
                    visibility: 'visible',
                    marginRight: theme.spacing(1),
                    color: theme.palette.stationGray[500],
                    '&:hover': {
                      color: theme.palette.primary.dark,
                    },
                  }}
                />
                <Typography sx={{ width: '20px', textAlign: 'center', color: theme.palette.stationGray[500] }}>
                  {index + 1}
                </Typography>
                <Typography sx={{ flex: 1, ml: theme.spacing(1.5) }}>{rank?.name}</Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: theme.spacing(2),
            gap: theme.spacing(1),
            mb: theme.spacing(2),
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
