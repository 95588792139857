import { parseISO } from 'date-fns';
import { useState } from 'react';
import { useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ShiftPlanAPIData, ShiftPlanChipType, ShiftPlanAction, ShiftPlanStruct } from '@stationwise/share-types';
import { makeDateInterval } from '@stationwise/share-utils';
import { initializeStations } from '../helpers/readStations';

interface UseShiftPlanProps {
  data: ShiftPlanAPIData;
  forceRefetch: (battalionId: string) => void;
}

export const useShiftPlan = (props: UseShiftPlanProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const shiftDuration = makeDateInterval(parseISO(props.data.date), departmentInfoState.departmentInfo.shiftStart, 24);
  const [initialStations] = useState(() => initializeStations(props.data));
  const [stations, setStations] = useState(initialStations);
  const [selectedChipType, setSelectedChipType] = useState(ShiftPlanChipType.ROSTER_EDITOR);
  const [selectedAction, setSelectedAction] = useState<ShiftPlanAction | null>(null);
  const [selectedStruct, setSelectedStruct] = useState<ShiftPlanStruct>({});

  const userHasMadeChanges = stations !== initialStations;

  const resetValues = () => {
    setStations(initialStations);
  };

  return {
    shiftDuration,
    battalion: props.data.battalion,
    stations,
    setStations,
    selectedChipType,
    setSelectedChipType,
    selectedAction,
    setSelectedAction,
    selectedStruct,
    setSelectedStruct,
    userHasMadeChanges,
    forceRefetch: props.forceRefetch,
    resetValues,
  };
};

export type UseShiftPlanReturnType = ReturnType<typeof useShiftPlan>;
