import { Box } from '@mui/material';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { GenericDrawerOrModal } from '../GenericDrawerOrModal';

interface TimePickerProps {
  openTimeSelect: boolean;
  setOpenTimeSelect: (value: boolean) => void;
  handleTime: (time: Date) => void;
  paddingBottom?: string;
  maxTime?: Date | null;
  minTime?: Date | null;
  timeSteps?: { hours: number; minutes: number };
  defaultValue?: Date;
}

export const TimePicker = ({
  openTimeSelect,
  setOpenTimeSelect,
  handleTime,
  paddingBottom,
  maxTime,
  minTime,
  timeSteps = { hours: 1, minutes: 15 },
  defaultValue = new Date('2022-04-17T00:15'),
}: TimePickerProps) => {
  const [originalDefaultValue, setOriginalDefaultValue] = useState<Date>(defaultValue);
  const [value, setValue] = useState<Date>(defaultValue);

  useEffect(() => {
    // update the value to the new default if the default value changes dates
    // e.g. the user has selected a new day
    if (!isSameDay(originalDefaultValue, defaultValue)) {
      setOriginalDefaultValue(defaultValue);
      setValue(defaultValue);
    }
  }, [defaultValue, originalDefaultValue]);

  const buttonDisabled = () => {
    let minTimeError = false;
    let maxTimeError = false;
    if (minTime) {
      const hours = value.getHours();
      const minutes = value.getMinutes();
      minTimeError = hours < minTime.getHours() || (hours === minTime.getHours() && minutes < minTime.getMinutes());
    }

    if (maxTime) {
      const hours = value.getHours();
      const minutes = value.getMinutes();
      maxTimeError = hours > maxTime.getHours() || (hours === maxTime.getHours() && minutes > maxTime.getMinutes());
    }
    return minTimeError || maxTimeError;
  };

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      drawerOpen={openTimeSelect}
      handleOnClose={() => setOpenTimeSelect(false)}
      loading={false}
      showHeader={false}
      disableFooter={false}
      footerButtons={[
        {
          label: 'Save',
          onClick: () => handleTime(value),
          backgroundColor: 'black',
          disabled: buttonDisabled(),
        },
      ]}
      paddingBottom={paddingBottom}
    >
      <div>
        <Box
          sx={(theme) => ({
            p: theme.spacing(2),
            width: '100%',
          })}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MultiSectionDigitalClock
              sx={{
                justifyContent: 'center',
                borderBottom: 'none',
                '.MuiMenuItem-root': { backgroundColor: 'white' },
                '.MuiMenuItem-root.Mui-selected': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '.MuiMenuItem-root.Mui-selected:hover': {
                  backgroundColor: 'black',
                  color: 'white',
                },
                '.MuiButtonBase-root': {
                  borderRadius: '8px',
                  fontSize: '20px',
                },
                '.MuiMultiSectionDigitalClock-root': {
                  backgroundColor: 'white',
                  overflowY: 'hidden',
                },
                '.MuiList-root': {
                  overflowY: 'scroll',
                  borderLeft: 'none',
                  marginX: '30px',
                },
                '.MuiList-root.MuiList-padding': { borderLeft: 'none' },
              }}
              value={value}
              onChange={(value) => value && setValue(value)}
              ampm={false}
              timeSteps={timeSteps}
              maxTime={maxTime ?? undefined}
              minTime={minTime ?? undefined}
            />
          </LocalizationProvider>
        </Box>
      </div>
    </GenericDrawerOrModal>
  );
};
