import { Box, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, memo } from 'react';
import { Actions, Apparatus, Position, Station } from '@stationwise/component-module';
import { RosterApparatus, RosterPosition, RosterStation, ShiftPlanAction, ShiftPlanStruct } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { AddStructPlaceholder } from './AddStructPlaceholder';
import { CertificationRequirementChips } from './CertificationRequirementChips';

interface ShiftPlanStationProps {
  station: RosterStation;
  isMoveApparatusPossible: boolean;
  setSelectedStruct: Dispatch<SetStateAction<ShiftPlanStruct>>;
  setSelectedAction: Dispatch<SetStateAction<ShiftPlanAction | null>>;
}

export const ShiftPlanStation = memo(({ station, ...props }: ShiftPlanStationProps) => {
  const theme = useTheme();

  const onEditStation = () => {
    props.setSelectedStruct({ station });
    props.setSelectedAction(ShiftPlanAction.UPSERT_STATION);
  };

  const onRemoveStation = () => {
    props.setSelectedStruct({ station });
    props.setSelectedAction(ShiftPlanAction.REMOVE_STATION);
  };

  const onAddApparatus = () => {
    props.setSelectedStruct({ station });
    props.setSelectedAction(ShiftPlanAction.UPSERT_APPARATUS);
  };

  const onEditApparatus = (apparatus: RosterApparatus) => {
    props.setSelectedStruct({ station, apparatus });
    props.setSelectedAction(ShiftPlanAction.UPSERT_APPARATUS);
  };

  const onToggleApparatusMode = (apparatus: RosterApparatus) => {
    props.setSelectedStruct({ station, apparatus });
    props.setSelectedAction(ShiftPlanAction.TOGGLE_APPARATUS_MODE);
  };

  const onMoveApparatus = (apparatus: RosterApparatus) => {
    props.setSelectedStruct({ station, apparatus });
    props.setSelectedAction(ShiftPlanAction.MOVE_APPARATUS);
  };

  const onRemoveApparatus = (apparatus: RosterApparatus) => {
    props.setSelectedStruct({ station, apparatus });
    props.setSelectedAction(ShiftPlanAction.REMOVE_APPARATUS);
  };

  const onAddPosition = (apparatus: RosterApparatus) => {
    props.setSelectedStruct({ station, apparatus });
    props.setSelectedAction(ShiftPlanAction.UPSERT_POSITION);
  };

  const onEditPosition = (apparatus: RosterApparatus, position: RosterPosition) => {
    props.setSelectedStruct({ station, apparatus, position });
    props.setSelectedAction(ShiftPlanAction.WARN_EDIT_POSITION);
  };

  const onRemovePosition = (apparatus: RosterApparatus, position: RosterPosition) => {
    props.setSelectedStruct({ station, apparatus, position });
    props.setSelectedAction(ShiftPlanAction.REMOVE_POSITION);
  };

  const getStationActions = () => {
    const actions = [];
    if (station.stationId !== 'floater-station') {
      actions.push({ label: 'Edit station', onClick: onEditStation });
      actions.push({ label: 'Remove station', onClick: onRemoveStation });
      actions.push({ label: 'Add apparatus', onClick: onAddApparatus });
    }
    if (actions.length === 0) {
      return null;
    }
    return <Actions actions={actions} color={theme.palette.stationGray[500]} hoverColor={theme.palette.common.white} />;
  };

  const getApparatusActions = (apparatus: RosterApparatus) => {
    const actions = [];
    if (station.stationId !== 'floater-station') {
      if (!apparatus.isNewlyCreated) {
        actions.push({
          label: apparatus.isReserved ? 'Activate' : 'Put on reserve',
          onClick: () => onToggleApparatusMode(apparatus),
        });
        if (props.isMoveApparatusPossible) {
          actions.push({ label: 'Move apparatus', onClick: () => onMoveApparatus(apparatus) });
        }
      }
      actions.push({ label: 'Edit apparatus', onClick: () => onEditApparatus(apparatus) });
      actions.push({ label: 'Remove apparatus', onClick: () => onRemoveApparatus(apparatus) });
      actions.push({ label: 'Add position', onClick: () => onAddPosition(apparatus) });
    }
    if (actions.length === 0) {
      return null;
    }
    return <Actions actions={actions} />;
  };

  const getPositionActions = (apparatus: RosterApparatus, position: RosterPosition) => {
    const actions = [];
    if (station.stationId !== 'floater-station') {
      if (!position.isTemporary) {
        actions.push({ label: 'Edit position', onClick: () => onEditPosition(apparatus, position) });
      }
      actions.push({ label: 'Remove position', onClick: () => onRemovePosition(apparatus, position) });
    }
    if (actions.length === 0) {
      return null;
    }
    return (
      <Box onClick={(e) => e.stopPropagation()}>
        <Actions actions={actions} hoverColor="inherit" />
      </Box>
    );
  };

  return (
    <Station
      station={station}
      actions={getStationActions()}
      certificationRequirements={<CertificationRequirementChips certificationRequirements={station.certificationRequirements} />}
    >
      {station.stationId !== 'floater-station' && station.apparatuses.length === 0 && (
        <AddStructPlaceholder onClick={onAddApparatus}>+ Add apparatus</AddStructPlaceholder>
      )}
      {station.apparatuses.map((apparatus) => (
        <Apparatus key={apparatus.id} apparatus={apparatus} actions={getApparatusActions(apparatus)}>
          {apparatus.certificationRequirements.length > 0 && (
            <CertificationRequirementChips certificationRequirements={apparatus.certificationRequirements} />
          )}
          {station.stationId !== 'floater-station' && apparatus.positions.length === 0 && (
            <AddStructPlaceholder onClick={() => onAddPosition(apparatus)} sx={{ mt: 1.5 }}>
              + Add position
            </AddStructPlaceholder>
          )}
          {apparatus.positions.map((position) => (
            <Box key={position.id} data-cy={`position-${makeTestIdentifier(position.rank.code)}`} sx={{ mt: 1.5 }}>
              <Position
                apparatus={apparatus}
                position={position}
                isCollapsed={false}
                actions={getPositionActions(apparatus, position)}
              />
            </Box>
          ))}
        </Apparatus>
      ))}
    </Station>
  );
});

ShiftPlanStation.displayName = 'ShiftPlanStation';
