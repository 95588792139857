import { ReactComponent as KeyboardArrowDownSymbol } from '@material-symbols/svg-400/outlined/keyboard_arrow_down.svg';
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Button, SvgIcon, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { Certification, CertificationRequirement, RosterStation } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../../contexts/ShiftTemplateContext';
import { buildAddress, buildStation } from '../../stations';
import { CertificationRequirementCounter } from '../Certification/CertificationRequirementCounter';
import { AddressForm } from './AddressForm';

const newStation: RosterStation = buildStation({ name: '', address: buildAddress(), certificationRequirements: [] });

export const StationDialog = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  const getInitialCertifications = (selectedStation: RosterStation | null) => {
    return departmentInfoState.departmentInfo.certifications.map((certification: Certification) => ({
      code: certification.code,
      name: certification.name,
      color: certification.color,
      id: certification.id,
      amount:
        selectedStation?.certificationRequirements.find(
          (certReq: CertificationRequirement) => certReq.code === certification.code,
        )?.amount || 0,
    }));
  };

  const { shiftTemplateHelper, addStation, editStation, selectedStation, isStationDialogOpen, setIsStationDialogOpen } =
    useShiftTemplateContext();

  const fakeStationNames = ['__events__', 'event groups', 'hq', 'training', 'temporary assignment'];
  const existingStationNames = Array.from(shiftTemplateHelper.allStationCards.values())
    .map((station) => station.stationName.toLowerCase())
    .concat(fakeStationNames);
  const [station, setStation] = useState<RosterStation>(selectedStation ? selectedStation : newStation);
  const [certRequirements, setCertRequirements] = useState<CertificationRequirement[]>(() =>
    getInitialCertifications(selectedStation),
  );
  const [error, setError] = useState({ duplicatedStation: false });

  useEffect(() => {
    if (selectedStation) {
      const newCertRequirements = certRequirements.map((certificationReq: CertificationRequirement) => ({
        ...certificationReq,
        amount: selectedStation.certificationRequirements.find((cert) => cert.code === certificationReq.code)?.amount || 0,
      }));
      setCertRequirements(newCertRequirements);
      setStation(selectedStation);
    } else {
      setStation(newStation);
      setCertRequirements(getInitialCertifications(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStation, isStationDialogOpen]);

  const onSave = () => {
    const payload = {
      ...station,
      certificationRequirements: certRequirements.filter((certReq) => certReq.amount > 0),
    };
    if (selectedStation) {
      editStation(payload);
    } else {
      addStation({ ...payload, stationId: payload.stationName });
    }
    closeDialog();
  };

  const closeDialog = () => {
    setIsStationDialogOpen(false);
  };

  return (
    <Dialog open={isStationDialogOpen} onClose={closeDialog} scroll="paper">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box sx={(theme) => ({ typography: 'bodyXLSemibold', textAlign: 'center', mt: theme.spacing(1) })}>
            {selectedStation ? 'Edit station' : 'Add station'}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box width="428px">
          <Box
            sx={(theme) => ({
              pt: theme.spacing(1.5),
              justifyContent: 'center',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 2,
                width: '100%',
                '.MuiInputLabel-root': {
                  top: '-5px',
                },
              },
            })}
          >
            <TextField
              label="Name"
              value={station.stationName}
              error={error.duplicatedStation}
              helperText={error.duplicatedStation ? 'This station already exists, please choose another name.' : ''}
              onChange={(event) => {
                setStation({ ...station, stationName: event.currentTarget.value });
                if (existingStationNames.includes(event.currentTarget.value.toLowerCase())) {
                  setError({ ...error, duplicatedStation: true });
                } else if (error.duplicatedStation) {
                  setError({ ...error, duplicatedStation: false });
                }
              }}
              required
            />
            <Accordion>
              <AccordionSummary
                expandIcon={<SvgIcon component={KeyboardArrowDownSymbol} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Address
              </AccordionSummary>
              <AccordionDetails>
                <AddressForm station={station} setStation={setStation} />
              </AccordionDetails>
            </Accordion>
            <Box
              sx={(theme) => ({
                typography: 'eyebrow',
                lineHeight: '20px',
                mb: theme.spacing(2),
                mt: theme.spacing(2.5),
                color: theme.palette.stationGray[700],
              })}
            >
              Station level requirements
            </Box>
            {certRequirements.map((requirement) => (
              <CertificationRequirementCounter
                key={requirement.id}
                setCertRequirements={setCertRequirements}
                certRequirements={certRequirements}
                currentCertificationRequirement={requirement}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={(theme) => ({
            justifyContent: 'center',
            display: 'flex',
            gap: '16px',
            mt: theme.spacing(2),
            mb: theme.spacing(2),
            width: '100%',
          })}
        >
          <Button
            buttonType="tertiary"
            variant="contained"
            sx={() => ({
              width: '45%',
              height: '44px',
              padding: '9px 17px 9px 17px',
            })}
            onClick={closeDialog}
          >
            <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
              Cancel
            </Box>
          </Button>
          <Button
            sx={(theme) => ({
              width: '45%',
              height: '44px',
              padding: '9px 17px 9px 17px',
              backgroundColor: theme.palette.stationBlue[700],
              color: theme.palette.common.white,
              borderRadius: theme.spacing(0.75),
              '&:hover': { backgroundColor: theme.palette.stationBlue[700], boxShadow: 'none' },
            })}
            buttonType="tertiary"
            variant="contained"
            disableFocusRipple={true}
            disableTouchRipple={true}
            disabled={!station.stationName}
            onClick={onSave}
          >
            <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
              Save
            </Box>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
