import { Box, ButtonBase, useTheme } from '@mui/material';
import { Button } from '@stationwise/component-module';
import { ShiftPlanChipType } from '@stationwise/share-types';
import { useShiftPlanContext } from '../ShiftPlanContext';
import { BattalionSelect } from './BattalionSelect';

export const TopBar = () => {
  const theme = useTheme();
  const { selectedChipType, setSelectedChipType, userHasMadeChanges, resetValues } = useShiftPlanContext();

  const chips = [{ type: ShiftPlanChipType.ROSTER_EDITOR, label: 'Roster editor' }];

  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        p: theme.spacing(1, 3),
      }}
    >
      <BattalionSelect />
      <Box sx={{ flex: 1, display: 'flex', px: 3 }}>
        {chips.map((chip) => (
          <ButtonBase
            key={chip.type}
            disableRipple={chip.type === selectedChipType}
            onClick={() => setSelectedChipType(chip.type)}
            sx={{
              background: chip.type === selectedChipType ? theme.palette.action.selected : 'transparent',
              borderRadius: '9999px',
              cursor: chip.type === selectedChipType ? 'default' : 'pointer',
              p: theme.spacing(1, 2),
              typography: 'bodyMRegular',
            }}
          >
            {chip.label}
          </ButtonBase>
        ))}
      </Box>
      <Button buttonType="tertiary" disabled={!userHasMadeChanges} onClick={resetValues} data-cy="cancel">
        Cancel
      </Button>
    </Box>
  );
};
