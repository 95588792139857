import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericDrawerOrModal, theme, useAuthUserContext } from '@stationwise/component-module';
import { ROUTES } from '../../../../../core/Routes';
import { MessagesInboxAlert } from './MessagesInboxAlert';

export const PendingMessages = () => {
  const [messagesAlertOpen, setMessagesAlertOpen] = useState(true);
  const navigate = useNavigate();

  const { state } = useAuthUserContext();
  const unreadMessages = state.employee?.unreadMessages.length ?? 0;

  const closeMessagesAlert = () => {
    setMessagesAlertOpen(false);
  };

  const goToInbox = () => {
    const queryParam = '?openMessagesPanel=true';
    navigate(`${ROUTES.INBOX.fullRoute}${queryParam}`);
  };

  return (
    <GenericDrawerOrModal
      anchor="bottom"
      handleOnClose={closeMessagesAlert}
      footerButtons={[
        {
          label: 'Read new messages',
          onClick: goToInbox,
          backgroundColor: theme.palette.stationGray[900],
        },
      ]}
      drawerOpen={messagesAlertOpen}
      loading={false}
      sxProps={{
        '.MuiDrawer-paper': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          pb: theme.spacing(2),
        },
        '.css-rynkgn-MuiButtonBase-root-MuiButton-root': {
          boxShadow: 'none',
          fontWeight: 600,
        },
      }}
    >
      <MessagesInboxAlert messages={unreadMessages} />
    </GenericDrawerOrModal>
  );
};
