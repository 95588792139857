import { Alert, Box, Modal } from '@mui/material';
import { setHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ArrowRightIcon20, Button, useRosterContext, XCloseIcon24 } from '@stationwise/component-module';
import { ForceShiftTradeEligibleEmployee } from '@stationwise/share-types';
import { forceShiftTrade } from '@stationwise/shift-summary-helper';
import { Footer } from './Footer';
import { SelectAvailableEmployee } from './SelectAvailableEmployee';
import { ShiftTradeHeader } from './ShiftTradeHeader';
import { ShiftTradeOptions, getShiftTradeDuration } from './ShiftTradeOptions';

export const ForceShiftTradeModal = () => {
  const {
    preferences,
    forceShiftTradeState,
    selectedFilledPositionState,
    shiftSummaryHelper,
    setShiftSummaryHelper,
    setUserHasMadeChanges,
    setLastChanges,
  } = useRosterContext();
  const isOvertimeShiftTrade = forceShiftTradeState.isOvertimeShiftTrade;
  const { employee } = selectedFilledPositionState;

  const [step, setStep] = useState(1);
  const [start, setStart] = useState(shiftSummaryHelper.shiftDuration.startTime);
  const [duration, setDuration] = useState(0);
  const end = setHours(start, start.getHours() + duration);
  const [receiverEmployee, setReceiverEmployee] = useState<ForceShiftTradeEligibleEmployee | null>(null);

  useEffect(() => {
    if (employee) {
      setStart(employee.startDateTime);
      setDuration(getShiftTradeDuration(employee.startDateTime, employee.endDateTime));
    }
  }, [employee]);

  const handleClose = () => {
    selectedFilledPositionState.closeDialog();
    forceShiftTradeState.setIsDialogOpen(false);
    setStep(1);
    employee && setStart(employee.startDateTime);
    employee && setDuration(getShiftTradeDuration(employee.startDateTime, employee.endDateTime));
    setReceiverEmployee(null);
  };

  const handleSubmit = () => {
    if (!employee || !receiverEmployee || !preferences.selectedBattalionId) return;

    const { newShiftSummaryHelper, newEmployee, error } = forceShiftTrade(
      shiftSummaryHelper,
      employee.id,
      { ...receiverEmployee, name: `${receiverEmployee.firstName} ${receiverEmployee.lastName}` },
      start,
      end,
      isOvertimeShiftTrade,
    );
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    newEmployee && error && setLastChanges({ shiftSummaryHelper, employee: newEmployee, error });
    handleClose();
  };

  if (!employee) {
    return null;
  }

  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={forceShiftTradeState.isDialogOpen}
      onClose={() => handleClose()}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          justifyContent: 'center',
          borderRadius: theme.spacing(1.5),
          p: theme.spacing(0),
          width: '428px',
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          maxHeight: '800px',
        })}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                px: theme.spacing(3),
                pt: theme.spacing(3),
                typography: 'bodyXLSemibold',
                display: 'flex',
              })}
            >
              {isOvertimeShiftTrade ? 'Force overtime trade' : 'Force shift trade'}
            </Box>
            <Box
              sx={(theme) => ({
                pr: theme.spacing(1.5),
                pt: theme.spacing(1.5),
                cursor: 'pointer',
                '& svg': {
                  path: {
                    stroke: theme.palette.stationGray[400],
                  },
                  float: 'right',
                },
              })}
              onClick={() => handleClose()}
            >
              <XCloseIcon24 />
            </Box>
          </Box>
          {isOvertimeShiftTrade && (
            <Alert severity="info" sx={(theme) => ({ mt: 1, mb: 2, mx: 1, backgroundColor: theme.palette.stationBlue[200] })}>
              • Trade responder will receive overtime pay
              <br />
              • Trade requester will be deprioritized for staffing list
              <br />• There will be no trade debt between the employees
            </Alert>
          )}

          <ShiftTradeHeader employee={employee} start={start} end={end} />

          {step === 1 && (
            <Box sx={(theme) => ({ p: theme.spacing(3) })}>
              <ShiftTradeOptions
                startDateTime={start}
                setStartDateTime={setStart}
                duration={duration}
                setDuration={setDuration}
                originalEndDateTime={employee.endDateTime}
                originalStartDateTime={employee.startDateTime}
              />
              <Box
                sx={(theme) => ({
                  mt: theme.spacing(3),
                })}
              >
                <Button
                  variant="contained"
                  sx={(theme) => ({
                    background: theme.palette.stationGray[900],
                    '&:hover': { backgroundColor: theme.palette.stationGray[900], boxShadow: 'none' },
                    width: '100%',
                    textTransform: 'none',
                    borderRadius: '6px',
                    padding: '9px 15px 9px 17px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    gap: '12px',
                    flex: '1 0 0',
                  })}
                  onClick={() => setStep(2)}
                  disabled={duration === 0}
                >
                  <Box sx={{ typography: 'buttonL', display: 'flex' }}>
                    Select people to trade with
                    <Box sx={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
                      <ArrowRightIcon20 />
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>
          )}
          {step === 2 && (
            <>
              <Box sx={(theme) => ({ p: theme.spacing(3), height: '400px', overflowY: 'scroll' })}>
                <SelectAvailableEmployee
                  employeeId={employee.id}
                  start={start}
                  end={end}
                  handleSubmit={handleSubmit}
                  receiverEmployee={receiverEmployee}
                  setReceiverEmployee={setReceiverEmployee}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  px: theme.spacing(3),
                  height: '80px',

                  boxShadow: '0px -4px 6px -1px rgba(10, 14, 22, 0.10), 0px -2px 4px -1px rgba(10, 14, 22, 0.06)',
                })}
              >
                <Footer handleSubmit={handleSubmit} setStep={setStep} selectedPerson={receiverEmployee?.id ?? ''} />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
